<template>
  <div class="wrapper">
    <data-filter :on-apply-filter="onApplyFilter" hide-report download-excel :on-download-excel="onDownloadExcel" :minute=true />

    <div class="wrapper mt-2">
      <div class="d-flex align-items-center">
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">Speed Unit</span>
          <select name="" class="form-control" v-model="unit.speed">
            <option value="knot">KNOT</option>
            <option value="km/h">KM/H</option>
          </select>
        </div>
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">Distance Unit</span>
          <select name="" class="form-control" v-model="unit.distance">
            <option value="nm">NM</option>
            <option value="km">KM</option>
          </select>
        </div>
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">Sort Data By</span>
          <select name="" class="form-control" v-model="dataSort">
            <option value="latest">Latest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
        <div class="ml-3">
          <span class="d-block text-muted small text-left font-weight-bold mb-2">&nbsp;</span>
          <button class="btn btn-outline-primary" @click="showShowHideModal">
            Filter Column
          </button>
        </div>
      </div>
    </div>

    <!-- show/hide column modal -->
    <b-modal id="show-hide-column-modal" title="Show/Hide Datalog Column" size="xl">
      <div class="row">
        <div v-for="(columns, key) in availableColumns" :key="key" class="col-4">
          <h5>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h5>
          <div>
            <input
              type="checkbox"
              :id="`${key}-all`"
              :checked="areAllVisible(key)"
              @change="toggleAllColumns(key)"
            />
            <label :for="`${key}-all`" class="ml-1">
              {{ areAllVisible(key) ? 'Hide All' : 'Show All' }}
            </label>
          </div>
          <div v-for="column in columns" :key="column">
            <input
              type="checkbox"
              :id="column"
              :value="column"
              v-model="visibleColumns[key]"
            />
            <label :for="column" class="ml-1">{{ column.charAt(0).toUpperCase() + column.slice(1) }}</label>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- ./ show/hide column modal -->

    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>
    <template v-else-if="currAssetSummary && !isLoading">
      <div class="iq-card mt-3">
        <div class="iq-card-body pt-2 pb-5 mb-5">
          <div class="table-responsive">
            <div style="height: 70vh; overflow-y: auto;">
              <table class="table table-striped table-sm table-hover table-bordered text-center">
                <thead class="thead-light" style="position: sticky; top: 0; z-index: 1;">
                  <tr>
                    <th class="sticky-column" rowspan="2" style="min-width: 200px;">Date Time</th>
                    <th v-if="visibleColumns.gps.includes('coordinate') && interval === 'minute'" rowspan="2" style="min-width: 200px;">Coordinate</th>
                    <th v-if="visibleColumns.gps.includes('coordinate') && interval !== 'minute'" rowspan="2" style="min-width: 250px;">First Coordinate</th>
                    <th v-if="visibleColumns.gps.includes('coordinate') && interval !== 'minute'" rowspan="2" style="min-width: 250px;">Last Coordinate</th>
                    <th v-if="visibleColumns.gps.includes('heading')" rowspan="2" style="min-width: 120px;">Heading</th>
                    <th v-if="visibleColumns.gps.includes('speed')" rowspan="2" style="min-width: 120px;">Speed ({{ unit.speed.toUpperCase() }})</th>
                    <th v-if="visibleColumns.gps.includes('cruise')" rowspan="2" style="min-width: 120px;">Cruise ({{ unit.distance.toUpperCase() }})</th>

                    <th v-if="visiblePortColumnsCount > 0" :colspan="interval != 'minute' ? visiblePortColumnsCount + 1 : visiblePortColumnsCount">PORT</th>
                    <th v-if="visibleStarboardColumnsCount > 0" :colspan="interval != 'minute' ? visibleStarboardColumnsCount + 1 : visibleStarboardColumnsCount">STARBOARD</th>

                    <th v-if="visibleColumns.ae1.includes('runningHours')" colspan="2">AE 1</th>
                    <th v-if="visibleColumns.ae2.includes('runningHours')" colspan="2">AE 2</th>
                    <th v-if="visibleColumns.ae3.includes('runningHours')" colspan="2">AE 3</th>
                  </tr>
                  <tr>
                    <th v-if="visibleColumns.port.includes('rpm')" style="min-width: 120px;">RPM</th>
                    <th v-if="visibleColumns.port.includes('runningHours')" style="min-width: 120px;">Run. Hours</th>
                    <th v-if="visibleColumns.port.includes('volumeInventory') && interval === 'minute'" style="min-width: 150px;">Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.port.includes('volumeInventory') && interval !== 'minute'" style="min-width: 200px;">First Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.port.includes('volumeInventory') && interval !== 'minute'" style="min-width: 200px;">Last Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.port.includes('volumeFlowrate')" style="min-width: 120px;">In FLow (L/H)</th>
                    <th v-if="visibleColumns.port.includes('temperature')" style="min-width: 120px;">In Temp. (&deg;C)</th>
                    <th v-if="visibleColumns.port.includes('density')" style="min-width: 120px;">IN Density (g/cm&sup3;)</th>

                    <th v-if="visibleColumns.starboard.includes('rpm')" style="min-width: 120px;">RPM</th>
                    <th v-if="visibleColumns.starboard.includes('runningHours')" style="min-width: 120px;">Run. Hours</th>
                    <th v-if="visibleColumns.starboard.includes('volumeInventory') && interval === 'minute'" style="min-width: 150px;">Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.starboard.includes('volumeInventory') && interval !== 'minute'" style="min-width: 200px;">First Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.starboard.includes('volumeInventory') && interval !== 'minute'" style="min-width: 200px;">LAST Volume Total In (Liter)</th>
                    <th v-if="visibleColumns.starboard.includes('volumeFlowrate')" style="min-width: 120px;">In FLow (L/H)</th>
                    <th v-if="visibleColumns.starboard.includes('temperature')" style="min-width: 120px;">In Temp. (&deg;C)</th>
                    <th v-if="visibleColumns.starboard.includes('density')" style="min-width: 120px;">IN Density (g/cm&sup3;)</th>

                    <th v-if="visibleColumns.ae1.includes('runningHours')" style="min-width: 120px;">Run. Hours</th>
                    <th v-if="visibleColumns.ae1.includes('fuelConsumption')" style="min-width: 120px;">Fuel Cons (Liter)</th>

                    <th v-if="visibleColumns.ae2.includes('runningHours')" style="min-width: 120px;">Run. Hours</th>
                    <th v-if="visibleColumns.ae2.includes('fuelConsumption')" style="min-width: 120px;">Fuel Cons (Liter)</th>

                    <th v-if="visibleColumns.ae3.includes('runningHours')" style="min-width: 120px;">Run. Hours</th>
                    <th v-if="visibleColumns.ae3.includes('fuelConsumption')" style="min-width: 120px;">Fuel Cons (Liter)</th>
                  </tr>
                </thead>

                <tbody class="monospaced">
                  <tr v-for="data in currAssetSummary.series" :key="data.timestamp">
                    <td class="sticky-column"> {{ moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td v-if="visibleColumns.gps.includes('coordinate')" class="not-monospaced">{{
                      typeof data?.gpsData?.latitude !== 'undefined' && typeof data?.gpsData?.longitude !== 'undefined'
                        ? `${convertDecimalToDMS(data?.gpsData?.latitude, true)},
                        ${convertDecimalToDMS(data?.gpsData?.longitude, false)}`
                        : '-'
                    }}</td>
                    <td v-if="visibleColumns.gps.includes('coordinate')  && interval !== 'minute'" class="not-monospaced">{{
                      typeof data?.gpsData?.additional?.LAST_dlgpLatitude_4 !== 'undefined' && typeof data?.gpsData?.additional?.LAST_dlgpLongitude_5 !== 'undefined'
                        ? `${convertDecimalToDMS(data?.gpsData?.additional?.LAST_dlgpLatitude_4, true)},
                        ${convertDecimalToDMS(data?.gpsData?.additional?.LAST_dlgpLongitude_5, false)}`
                        : '-'
                    }}</td>
                    <td v-if="visibleColumns.gps.includes('heading')">{{ typeof data?.gpsData?.track !== 'undefined' ? data?.gpsData?.track.toFixed(2) : '-' }}&deg;</td>
                    <td v-if="visibleColumns.gps.includes('speed')">{{
                      typeof data?.gpsData?.speed !== 'undefined'
                        ? unit.speed === 'knot'
                          ? (data?.gpsData?.speed * 0.539957).toFixed(2) : data?.gpsData?.speed.toFixed(2)
                        : '-'
                    }}</td>
                    <td v-if="visibleColumns.gps.includes('cruise')">{{
                      typeof data?.gpsData?.additional?.SUM_dlgpDistance_3 !== 'undefined'
                        ? unit.distance === 'nm'
                          ? (data?.gpsData?.additional?.SUM_dlgpDistance_3 * 0.000539957).toFixed(2)
                          : (data?.gpsData?.additional?.SUM_dlgpDistance_3 * 0.001).toFixed(2)
                        : '-'
                    }}</td>

                    <td v-if="visibleColumns.port.includes('rpm')">{{ typeof data?.rpmData?.PORT?.rpm !== 'undefined' ? data?.rpmData?.PORT?.rpm.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('runningHours')">{{
                      typeof data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined'
                        ? toHHMMSS(data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 * 60)
                        : '-'
                    }}</td>
                    <td v-if="visibleColumns.port.includes('volumeInventory') && interval === 'minute'">{{ typeof data?.fmData?.PORT_IN?.volumeInventory !== 'undefined' ? data?.fmData?.PORT_IN?.volumeInventory.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('volumeInventory') && interval !== 'minute'">{{ typeof data?.fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0 !== 'undefined' ? data?.fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0?.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('volumeInventory') && interval !== 'minute'">{{ typeof data?.fmData?.PORT_IN?.additional?.LAST_dlfmVolInventory_1 !== 'undefined' ? data?.fmData?.PORT_IN?.additional?.LAST_dlfmVolInventory_1?.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('volumeFlowrate')">{{ typeof data?.fmData?.PORT_IN?.volumeFlowrate !== 'undefined' ? data?.fmData?.PORT_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('temperature')">{{ typeof data?.fmData?.PORT_IN?.temperature !== 'undefined' ? data?.fmData?.PORT_IN?.temperature.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.port.includes('density')">{{ typeof data?.fmData?.PORT_IN?.density !== 'undefined' ? data?.fmData?.PORT_IN?.density.toFixed(2) : '-' }}</td>

                    <td v-if="visibleColumns.starboard.includes('rpm')">{{ typeof data?.rpmData?.STARBOARD?.rpm !== 'undefined' ? data?.rpmData?.STARBOARD?.rpm.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('runningHours')">{{
                      typeof data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined'
                        ? toHHMMSS(data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 * 60)
                        : '-'
                    }}</td>
                    <td v-if="visibleColumns.starboard.includes('volumeInventory') && interval === 'minute'">{{ typeof data?.fmData?.STARBOARD_IN?.volumeInventory !== 'undefined' ? data?.fmData?.STARBOARD_IN?.volumeInventory.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('volumeInventory') && interval !== 'minute'">{{ typeof data?.fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0 !== 'undefined' ? data?.fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0?.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('volumeInventory') && interval !== 'minute'">{{ typeof data?.fmData?.STARBOARD_IN?.additional?.LAST_dlfmVolInventory_1 !== 'undefined' ? data?.fmData?.STARBOARD_IN?.additional?.LAST_dlfmVolInventory_1?.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('volumeFlowrate')">{{ typeof data?.fmData?.STARBOARD_IN?.volumeFlowrate !== 'undefined' ? data?.fmData?.STARBOARD_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('temperature')">{{ typeof data?.fmData?.STARBOARD_IN?.temperature !== 'undefined' ? data?.fmData?.STARBOARD_IN?.temperature.toFixed(2) : '-' }}</td>
                    <td v-if="visibleColumns.starboard.includes('density')">{{ typeof data?.fmData?.STARBOARD_IN?.density !== 'undefined' ? data?.fmData?.STARBOARD_IN?.density.toFixed(2) : '-' }}</td>

                    <td v-if="visibleColumns.ae1.includes('runningHours')">{{ typeof data?.aeData?.AE1?.runningSeconds !== 'undefined' ? toHHMMSS(data?.aeData?.AE1?.runningSeconds) : "-" }}</td>
                    <td v-if="visibleColumns.ae1.includes('fuelConsumption')">{{ typeof data?.aeData?.AE1?.fuelConsumption !== 'undefined' ? data?.aeData?.AE1?.fuelConsumption.toFixed(2) : "-" }}</td>

                    <td v-if="visibleColumns.ae2.includes('runningHours')">{{ typeof data?.aeData?.AE2?.runningSeconds !== 'undefined' ? toHHMMSS(data?.aeData?.AE2?.runningSeconds) : "-" }}</td>
                    <td v-if="visibleColumns.ae2.includes('fuelConsumption')">{{ typeof data?.aeData?.AE2?.fuelConsumption !== 'undefined' ? data?.aeData?.AE2?.fuelConsumption.toFixed(2) : "-" }}</td>

                    <td v-if="visibleColumns.ae3.includes('runningHours')">{{ typeof data?.aeData?.AE3?.runningSeconds !== 'undefined' ? toHHMMSS(data?.aeData?.AE3?.runningSeconds) : "-" }}</td>
                    <td v-if="visibleColumns.ae3.includes('fuelConsumption')">{{ typeof data?.aeData?.AE3?.fuelConsumption !== 'undefined' ? data?.aeData?.AE3?.fuelConsumption.toFixed(2) : "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <!-- <nav>
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">&laquo;</a>
            </li>
            <li class="page-item" :class="{ active: n === currentPage }" v-for="n in totalPages" :key="n">
              <a class="page-link" href="#" @click.prevent="changePage(n)">{{ n }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">&raquo;</a>
            </li>
          </ul>
        </nav> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import DataFilter from '@/components/DataFilter';
import { toHHMMSS, generateTimestampsInSeconds } from '@/util/date';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  name: 'FmsReportDatalog',
  components: {
    DataFilter,
  },
  data() {
    return {
      unit: {
        distance: 'nm',
        speed: 'knot',
      },
      dataSort: 'oldest',
      visibleColumns: {
        gps: ['coordinate', 'heading', 'speed', 'cruise'],
        port: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        starboard: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        ae1: ['runningHours', 'fuelConsumption'],
        ae2: ['runningHours', 'fuelConsumption'],
        ae3: ['runningHours', 'fuelConsumption'],
      },
      availableColumns: {
        gps: ['coordinate', 'heading', 'speed', 'cruise'],
        port: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        starboard: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        ae1: ['runningHours', 'fuelConsumption'],
        ae2: ['runningHours', 'fuelConsumption'],
        ae3: ['runningHours', 'fuelConsumption'],
      },
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
      isLoading: true,
    };
  },
  computed: {
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};

      if (!summary) return null;

      let intervalSeconds = 60;

      switch (this.interval) {
        case 'minute':
          intervalSeconds = 60;
          break;
        case 'hour':
          intervalSeconds = 60 * 60;
          break;
        case 'day':
          intervalSeconds = 60 * 60 * 24;
          break;
        case 'week':
          intervalSeconds = 60 * 60 * 24 * 7;
          break;
        default:
          break;
      }

      let timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), intervalSeconds);

      if (this.interval === 'hour') {
        timestamps = timestamps.map((t, i) => {
          let timestamp = t;
          if (i !== 0) timestamp = moment(timestamp * 1000).startOf('hour').unix();
          return timestamp;
        });
      }

      const roundedGps = {};
      Object.keys(summary.gps?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedGps[roundedKey] = summary.gps?.data[key];
      });

      const roundedRpm = {};
      Object.keys(summary.rpm?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedRpm[roundedKey] = summary.rpm?.data[key];
      });

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const roundedAe = {};
      Object.keys(summary.ae?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedAe[roundedKey] = summary.ae?.data[key];
      });

      let mappedSeries = timestamps.map((timestamp) => {
        const gpsData = roundedGps[timestamp] || {};
        const rpmData = roundedRpm[timestamp] || {
          STARBOARD: {},
          PORT: {},
        };
        const fmData = roundedFm[timestamp] || {};
        const aeData = roundedAe[timestamp] || {};
        return {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        };
      });

      if (this.dataSort === 'latest') {
        mappedSeries = mappedSeries.sort((a, b) => b.timestamp - a.timestamp);
      }

      if (this.dataSort === 'oldest') {
        mappedSeries = mappedSeries.sort((a, b) => a.timestamp - b.timestamp);
      }

      mappedSummary.series = mappedSeries;
      return mappedSummary;
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
      rangeType: (state) => state.rangeType,
    }),
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
    selectedRangeType: {
      get() {
        return this.rangeType;
      },
      set(val) {
        this.$store.dispatch('filter/setRangeType', val);
      },
    },
    visiblePortColumnsCount() {
      return Object.values(this.availableColumns.port)
        .filter((column) => this.visibleColumns.port.includes(column))
        .length;
    },
    visibleStarboardColumnsCount() {
      return Object.values(this.availableColumns.starboard)
        .filter((column) => this.visibleColumns.starboard.includes(column))
        .length;
    },
  },
  methods: {
    toHHMMSS,
    convertDecimalToDMS(decimal, isLatitude) {
      const degrees = Math.floor(Math.abs(decimal));
      const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
      const minutes = Math.floor(minutesDecimal);
      const seconds = Math.round((minutesDecimal - minutes) * 60);

      // eslint-disable-next-line no-nested-ternary
      const direction = decimal >= 0
        ? (isLatitude ? 'N' : 'E')
        : (isLatitude ? 'S' : 'W');

      return `${degrees}° ${minutes}' ${seconds}'' ${direction}`;
    },
    moment(date) {
      return moment(date);
    },
    ...mapActions(['updatekapalTrail']),
    async onApplyFilter() {
      this.isLoading = true;
      try {
        // await this.$store.dispatch('filter/setDate', this.dateRange);
        await this.$store.dispatch('filter/setCurrentMassId', this.selectedAsset || this.currentMassId);
        await this.$store.dispatch('filter/setInterval', this.selectedInterval || this.interval);

        await this.$store.dispatch('asset/fetchSummary', {
          start: this.dateRange.start,
          end: this.dateRange.end,
          interval: this.interval,
          massId: this.currentMassId,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async onDownloadExcel() {
      const getOrDefault = (value, defaultValue = '-') => {
        const returnValue = value !== undefined ? value : defaultValue;
        return returnValue;
      };

      // Map data untuk Excel
      const excelMappedSeries = this.currAssetSummary.series.map((data) => {
        const {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        } = data;

        const formatCoordinate = (firstOrLast = 'first') => {
          let coordinate = '-';

          if (firstOrLast === 'first' && getOrDefault(gpsData.latitude) !== '-' && getOrDefault(gpsData.longitude) !== '-') {
            coordinate = `${this.convertDecimalToDMS(gpsData.latitude, true)}, ${this.convertDecimalToDMS(gpsData.longitude, false)}`;
          }

          if (firstOrLast === 'last' && getOrDefault(gpsData.additional?.LAST_dlgpLatitude_4) !== '-' && getOrDefault(gpsData.additional?.LAST_dlgpLongitude_5) !== '-') {
            coordinate = `${this.convertDecimalToDMS(gpsData.additional?.LAST_dlgpLatitude_4, true)}, ${this.convertDecimalToDMS(gpsData.additional?.LAST_dlgpLongitude_5, false)}`;
          }

          return coordinate;
        };

        const formatSpeed = () => {
          const speed = getOrDefault(gpsData.speed);
          if (speed === '-') return '-';
          return parseFloat((this.unit.speed === 'knot' ? speed * 0.539957 : speed).toFixed(2));
        };

        const formatDistance = () => {
          const distance = getOrDefault(gpsData?.additional?.SUM_dlgpDistance_3);
          if (distance === '-') return '-';
          return parseFloat((this.unit.distance === 'nm' ? distance * 0.000539957 : distance * 0.001).toFixed(2));
        };

        const row = {
          DateTime: moment(timestamp * 1000).format('DD-MM-YYYY HH:mm:ss'),
        };

        if (this.visibleColumns.gps.includes('coordinate') && this.interval === 'minute') row.Coordinate = formatCoordinate();
        if (this.visibleColumns.gps.includes('coordinate') && this.interval === 'hour') {
          row.FirstCoordinate = formatCoordinate('first');
          row.LastCoordinate = formatCoordinate('last');
        }

        if (this.visibleColumns.gps.includes('heading')) row.Heading = getOrDefault(gpsData.track);
        if (this.visibleColumns.gps.includes('speed')) row.Speed = formatSpeed();
        if (this.visibleColumns.gps.includes('cruise')) row.Distance = formatDistance();

        if (this.visibleColumns.port.includes('rpm')) row.PortRpm = getOrDefault(rpmData?.PORT?.rpm);
        if (this.visibleColumns.port.includes('runningHours')) row.PortRunningHours = getOrDefault(rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1);
        if (this.visibleColumns.port.includes('volumeInventory') && this.interval === 'minute') row.PortVolumeInventory = getOrDefault(fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0);
        if (this.visibleColumns.port.includes('volumeInventory') && this.interval !== 'minute') {
          row.FirstPortVolumeInventory = getOrDefault(fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0);
          row.LastPortVolumeInventory = getOrDefault(fmData?.PORT_IN?.additional?.LAST_dlfmVolInventory_1);
        }

        if (this.visibleColumns.port.includes('volumeFlowrate')) row.PortInFlow = getOrDefault(fmData?.PORT_InFlow);
        if (this.visibleColumns.port.includes('temperature')) row.PortInTemp = getOrDefault(fmData?.PORT_IN?.temperature);
        if (this.visibleColumns.port.includes('density')) row.PortInDensity = getOrDefault(fmData?.PORT_IN?.density);

        if (this.visibleColumns.starboard.includes('rpm')) row.StbRpm = getOrDefault(rpmData?.STARBOARD?.rpm);
        if (this.visibleColumns.starboard.includes('runningHours')) row.StbRunningHours = getOrDefault(rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1);
        if (this.visibleColumns.starboard.includes('volumeInventory') && this.interval === 'minute') row.StbVolumeInventory = getOrDefault(fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0);
        if (this.visibleColumns.starboard.includes('volumeInventory') && this.interval !== 'minute') {
          row.FirstStbVolumeInventory = getOrDefault(fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0);
          row.LastStbVolumeInventory = getOrDefault(fmData?.STARBOARD_IN?.additional?.LAST_dlfmVolInventory_1);
        }

        if (this.visibleColumns.starboard.includes('volumeFlowrate')) row.StbInFlow = getOrDefault(fmData?.STARBOARD_InFlow);
        if (this.visibleColumns.starboard.includes('temperature')) row.StbInTemp = getOrDefault(fmData?.STARBOARD_IN?.temperature);
        if (this.visibleColumns.starboard.includes('density')) row.StbInDensity = getOrDefault(fmData?.STARBOARD_IN?.density);

        if (this.visibleColumns.ae1.includes('runningHours')) row.AE1RunningHours = getOrDefault(aeData?.AE1?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE1.runningSeconds) : '-';
        if (this.visibleColumns.ae1.includes('fuelConsumption')) row.AE1TotalFuelUsed = getOrDefault(aeData?.AE1?.fuelConsumption);

        if (this.visibleColumns.ae2.includes('runningHours')) row.AE2RunningHours = getOrDefault(aeData?.AE2?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE2.runningSeconds) : '-';
        if (this.visibleColumns.ae2.includes('fuelConsumption')) row.AE2TotalFuelUsed = getOrDefault(aeData?.AE2?.fuelConsumption);

        if (this.visibleColumns.ae3.includes('runningHours')) row.AE3RunningHours = getOrDefault(aeData?.AE3?.runningSeconds) !== '-' ? toHHMMSS(aeData.AE3.runningSeconds) : '-';
        if (this.visibleColumns.ae3.includes('fuelConsumption')) row.AE3TotalFuelUsed = getOrDefault(aeData?.AE3?.fuelConsumption);

        return row;
      });

      // Membuat workbook dan worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Asset Summary');

      // Menambahkan header
      const worksheetColumns = [
        { header: 'DateTime', key: 'DateTime', width: 20 },
      ];

      if (this.visibleColumns.gps.includes('coordinate') && this.interval === 'minute') worksheetColumns.push({ header: this.interval === 'minute' ? 'Coordinate' : 'First Coordinate', key: 'Coordinate', width: 30 });
      if (this.visibleColumns.gps.includes('coordinate') && this.interval !== 'minute') worksheetColumns.push({ header: 'Last Coordinate', key: 'LastCoordinate', width: 30 });
      if (this.visibleColumns.gps.includes('heading')) worksheetColumns.push({ header: 'Heading', key: 'Heading', width: 10 });
      if (this.visibleColumns.gps.includes('speed')) worksheetColumns.push({ header: `Speed (${this.unit.speed.toUpperCase()})`, key: 'Speed', width: 13 });
      if (this.visibleColumns.gps.includes('cruise')) worksheetColumns.push({ header: `Distance (${this.unit.distance.toUpperCase()})`, key: 'Distance', width: 13 });
      if (this.visibleColumns.port.includes('rpm')) worksheetColumns.push({ header: 'Port Rpm', key: 'PortRpm', width: 10 });
      if (this.visibleColumns.port.includes('runningHours')) worksheetColumns.push({ header: 'Port Running Hours', key: 'PortRunningHours', width: 20 });
      if (this.visibleColumns.port.includes('volumeInventory') && this.interval === 'minute') worksheetColumns.push({ header: 'Port Volume Inventory (liter)', key: 'PortVolumeInventory', width: 25 });
      if (this.visibleColumns.port.includes('volumeInventory') && this.interval !== 'minute') {
        worksheetColumns.push({ header: 'First Port Volume Inventory (liter)', key: 'FirstPortVolumeInventory', width: 25 });
        worksheetColumns.push({ header: 'Last Port Volume Inventory (liter)', key: 'LastPortVolumeInventory', width: 25 });
      }

      if (this.visibleColumns.port.includes('volumeFlowrate')) worksheetColumns.push({ header: 'Port In Flow (l/h)', key: 'PortInFlow', width: 15 });
      if (this.visibleColumns.port.includes('temperature')) worksheetColumns.push({ header: 'Port In Temp (°C)', key: 'PortInTemp', width: 15 });
      if (this.visibleColumns.port.includes('density')) worksheetColumns.push({ header: 'Port In Density (g/cm³)', key: 'PortInDensity', width: 20 });
      if (this.visibleColumns.starboard.includes('rpm')) worksheetColumns.push({ header: 'Stbd Rpm', key: 'StbRpm', width: 10 });
      if (this.visibleColumns.starboard.includes('runningHours')) worksheetColumns.push({ header: 'Stbd Running Hours', key: 'StbRunningHours', width: 20 });
      if (this.visibleColumns.starboard.includes('volumeInventory') && this.interval === 'minute') worksheetColumns.push({ header: 'Stbd Volume Inventory (liter)', key: 'StbVolumeInventory', width: 25 });
      if (this.visibleColumns.starboard.includes('volumeInventory') && this.interval !== 'minute') {
        worksheetColumns.push({ header: 'First Stbd Volume Inventory (liter)', key: 'FirstStbVolumeInventory', width: 25 });
        worksheetColumns.push({ header: 'Last Stbd Volume Inventory (liter)', key: 'LastStbVolumeInventory', width: 25 });
      }

      if (this.visibleColumns.starboard.includes('volumeFlowrate')) worksheetColumns.push({ header: 'Stbd In Flow (l/h)', key: 'StbInFlow', width: 15 });
      if (this.visibleColumns.starboard.includes('temperature')) worksheetColumns.push({ header: 'Stbd In Temp (°C)', key: 'StbInTemp', width: 15 });
      if (this.visibleColumns.starboard.includes('density')) worksheetColumns.push({ header: 'Stbd In Density (g/cm³)', key: 'StbInDensity', width: 20 });
      if (this.visibleColumns.ae1.includes('runningHours')) worksheetColumns.push({ header: 'AE1 Running Hours', key: 'AE1RunningHours', width: 20 });
      if (this.visibleColumns.ae1.includes('fuelConsumption')) worksheetColumns.push({ header: 'AE1 Total Fuel Used', key: 'AE1TotalFuelUsed', width: 20 });
      if (this.visibleColumns.ae2.includes('runningHours')) worksheetColumns.push({ header: 'AE2 Running Hours', key: 'AE2RunningHours', width: 20 });
      if (this.visibleColumns.ae2.includes('fuelConsumption')) worksheetColumns.push({ header: 'AE2 Total Fuel Used', key: 'AE2TotalFuelUsed', width: 20 });
      if (this.visibleColumns.ae3.includes('runningHours')) worksheetColumns.push({ header: 'AE3 Running Hours', key: 'AE3RunningHours', width: 20 });

      if (this.visibleColumns.ae3.includes('fuelConsumption')) worksheetColumns.push({ header: 'AE3 Total Fuel Used', key: 'AE3TotalFuelUsed', width: 20 });

      worksheet.columns = worksheetColumns;

      // Memasukkan data ke worksheet
      excelMappedSeries.forEach((row) => {
        worksheet.addRow(row);
      });

      const filename = `${this.currAsset?.massName} - ${moment(this.dateRange.start).format('DD-MM-YYYY HH:mm')} - ${moment(this.dateRange.end).format('DD-MM-YYYY HH:mm')}`;

      // Menyimpan file Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${filename}.xlsx`);
    },
    showShowHideModal() {
      this.$bvModal.show('show-hide-column-modal');
    },
    hideShowHideModal() {
      this.$bvModal.hide('show-hide-column-modal');
    },
    areAllVisible(key) {
      return this.availableColumns[key].every((column) => this.visibleColumns[key].includes(column));
    },
    toggleAllColumns(key) {
      if (this.areAllVisible(key)) {
        this.visibleColumns[key] = [];
      } else {
        this.visibleColumns[key] = [...this.availableColumns[key]];
      }
    },
  },
  async mounted() {
    const interval = this.$store.getters['filter/interval'];
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });
    this.isLoading = false;
  },
};

</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  /* padding: 10px 20px; */
  margin: 0 5px;
}

@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }

  .flex-item {
    width: 100%;
  }
}

.sticky-column {
  position: sticky;
  left: 0;
}

.sticky-column:nth-child(odd) {
  background-color: white !important;
}

.sticky-column:nth-child(even) {
  background-color: #f5f5f5 !important;
}
</style>

<template>
  <div id="voyage-menu">
    <div id="menu-bar">
      <div id="close-menu" @click="$emit('toggleMenu')">
        <img :src="icon.CloseMenuIcon" width="75%" />
      </div>

      <div v-if="menuActive === 0" id="datalog-header" class="d-flex align-items-center">
        <div class="ml-3">
          <select name="" class="form-control" v-model="unit.speed">
            <option value="knot">KNOT</option>
            <option value="km/h">KM/H</option>
          </select>
        </div>
        <div class="ml-3">
          <select name="" class="form-control" v-model="unit.distance">
            <option value="nm">NM</option>
            <option value="km">KM</option>
          </select>
        </div>
        <div class="ml-3">
          <select name="" class="form-control" v-model="dataSort">
            <option value="latest">Latest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
        <div class="ml-3">
          <button class="btn btn-outline-primary" @click="showShowHideModal">
            Filter Column
          </button>
        </div>
        <div class="ml-3">
          <button class="btn btn-success" @click="onDownloadExcel">
            Download XLSX
          </button>
        </div>

        <!-- show/hide column modal -->
        <b-modal id="show-hide-column-modal" title="Show/Hide Datalog Column" size="xl">
          <div class="row">
            <div v-for="(columns, key) in availableColumns" :key="key" class="col-4">
              <h5>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h5>
              <div>
                <input type="checkbox" :id="`${key}-all`" :checked="areAllVisible(key)"
                  @change="toggleAllColumns(key)" />
                <label :for="`${key}-all`" class="ml-1">
                  {{ areAllVisible(key) ? 'Hide All' : 'Show All' }}
                </label>
              </div>
              <div v-for="column in columns" :key="column">
                <input type="checkbox" :id="column" :value="column" v-model="visibleColumns[key]" />
                <label :for="column" class="ml-1">{{ column.charAt(0).toUpperCase() + column.slice(1) }}</label>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- ./ show/hide column modal -->
      </div>

      <div v-if="menuActive === 1" id="chart-header">
        <div class="chart-selector" :class="{ 'active-chart-selector': chartActive === 0 }" @click="chartActive = 0">
          RPM, Fuel Cons, Speed
        </div>
        <div class="chart-selector" :class="{ 'active-chart-selector': chartActive === 1 }" @click="chartActive = 1">
          Port Fuel Characteristic
        </div>
        <div class="chart-selector" :class="{ 'active-chart-selector': chartActive === 2 }" @click="chartActive = 2">
          STBD Fuel Characteristic
        </div>
        <div class="chart-selector" :class="{ 'active-chart-selector': chartActive === 3 }" @click="chartActive = 3">
          Auxiliary Engine Usage
        </div>
      </div>
    </div>
    <div id="menu-content">
      <div id="content-selector">
        <div class="selector" :class="{ 'active-selector': menuActive === 0 }" @click="menuActive = 0">
          <img :src="menuActive === 0 ? icon.TableIconLight : icon.TableIcon" width="75%" />
        </div>
        <div class="selector" :class="{ 'active-selector': menuActive === 1 }" @click="menuActive = 1">
          <img :src="menuActive === 1 ? icon.ChartLineIconLight : icon.ChartLineIcon" width="75%" />
        </div>
      </div>

      <div v-if="menuActive === 0" id="datalog-content">
        <table v-if="currAssetSummary">
          <thead>
            <tr>
              <th class="sticky-column" rowspan="2">No</th>
              <th class="sticky-column" rowspan="2">Date Time</th>
              <th v-if="visibleColumns.gps.includes('coordinate')" rowspan="2">First Coordinate
              </th>
              <th v-if="visibleColumns.gps.includes('coordinate')" rowspan="2">Last Coordinate
              </th>
              <th v-if="visibleColumns.gps.includes('heading')" rowspan="2">Heading</th>
              <th v-if="visibleColumns.gps.includes('speed')" rowspan="2">Speed ({{ unit.speed.toUpperCase() }})</th>
              <th v-if="visibleColumns.gps.includes('cruise')" rowspan="2">Cruise ({{ unit.distance.toUpperCase() }})
              </th>
              <th v-if="visiblePortColumnsCount > 0" :colspan="visiblePortColumnsCount + 1">PORT</th>
              <th v-if="visibleStarboardColumnsCount > 0" :colspan="visibleStarboardColumnsCount + 1">STARBOARD</th>

              <th v-if="visibleColumns.ae1.includes('runningHours')" colspan="2">AE 1</th>
              <th v-if="visibleColumns.ae2.includes('runningHours')" colspan="2">AE 2</th>
              <th v-if="visibleColumns.ae3.includes('runningHours')" colspan="2">AE 3</th>
            </tr>
            <tr>
              <th v-if="visibleColumns.port.includes('rpm')">RPM</th>
              <th v-if="visibleColumns.port.includes('runningHours')">Run. Hours</th>
              <th v-if="visibleColumns.port.includes('volumeInventory')">First Volume Total In (Liter)</th>
              <th v-if="visibleColumns.port.includes('volumeInventory')">Last Volume Total In (Liter)</th>
              <th v-if="visibleColumns.port.includes('volumeFlowrate')">In FLow (L/H)</th>
              <th v-if="visibleColumns.port.includes('temperature')">In Temp. (&deg;C)</th>
              <th v-if="visibleColumns.port.includes('density')">IN Density (g/cm&sup3;)</th>

              <th v-if="visibleColumns.starboard.includes('rpm')">RPM</th>
              <th v-if="visibleColumns.starboard.includes('runningHours')">Run. Hours</th>
              <th v-if="visibleColumns.starboard.includes('volumeInventory')">First Volume Total In (Liter)</th>
              <th v-if="visibleColumns.starboard.includes('volumeInventory')">LAST Volume Total In (Liter)</th>
              <th v-if="visibleColumns.starboard.includes('volumeFlowrate')">In FLow (L/H)</th>
              <th v-if="visibleColumns.starboard.includes('temperature')">In Temp. (&deg;C)</th>
              <th v-if="visibleColumns.starboard.includes('density')">IN Density (g/cm&sup3;)</th>

              <th v-if="visibleColumns.ae1.includes('runningHours')">Run. Hours</th>
              <th v-if="visibleColumns.ae1.includes('fuelConsumption')">Fuel Cons (Liter)</th>

              <th v-if="visibleColumns.ae2.includes('runningHours')">Run. Hours</th>
              <th v-if="visibleColumns.ae2.includes('fuelConsumption')">Fuel Cons (Liter)</th>

              <th v-if="visibleColumns.ae3.includes('runningHours')">Run. Hours</th>
              <th v-if="visibleColumns.ae3.includes('fuelConsumption')">Fuel Cons (Liter)</th>
            </tr>
          </thead>

          <tbody class="monospaced">
            <tr v-for="data in currAssetSummary.series" :key="data.timestamp">
              <td class="sticky-column"> {{ currAssetSummary.series.indexOf(data) + 1 }} </td>
              <td class="sticky-column"> {{ moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') }}</td>
              <td v-if="visibleColumns.gps.includes('coordinate')" class="not-monospaced">{{
                typeof data?.gpsData?.latitude !== 'undefined' && typeof data?.gpsData?.longitude !== 'undefined'
                  ? `${convertDecimalToDMS(data?.gpsData?.latitude, true)},
                ${convertDecimalToDMS(data?.gpsData?.longitude, false)}`
                  : '-'
              }}</td>
              <td v-if="visibleColumns.gps.includes('coordinate')" class="not-monospaced">{{
                typeof data?.gpsData?.additional?.LAST_dlgpLatitude_4 !== 'undefined' && typeof
                  data?.gpsData?.additional?.LAST_dlgpLongitude_5 !== 'undefined'
                  ? `${convertDecimalToDMS(data?.gpsData?.additional?.LAST_dlgpLatitude_4, true)},
                ${convertDecimalToDMS(data?.gpsData?.additional?.LAST_dlgpLongitude_5, false)}`
                  : '-'
              }}</td>
              <td v-if="visibleColumns.gps.includes('heading')">{{ typeof data?.gpsData?.track !== 'undefined' ?
                data?.gpsData?.track.toFixed(2) : '-' }}&deg;</td>
              <td v-if="visibleColumns.gps.includes('speed')">{{
                typeof data?.gpsData?.speed !== 'undefined'
                  ? unit.speed === 'knot'
                    ? (data?.gpsData?.speed * 0.539957).toFixed(2) : data?.gpsData?.speed.toFixed(2)
                  : '-'
              }}</td>
              <td v-if="visibleColumns.gps.includes('cruise')">{{
                typeof data?.gpsData?.additional?.SUM_dlgpDistance_3 !== 'undefined'
                  ? unit.distance === 'nm'
                    ? (data?.gpsData?.additional?.SUM_dlgpDistance_3 * 0.000539957).toFixed(2)
                    : (data?.gpsData?.additional?.SUM_dlgpDistance_3 * 0.001).toFixed(2)
                  : '-'
              }}</td>

              <td v-if="visibleColumns.port.includes('rpm')">{{ typeof data?.rpmData?.PORT?.rpm !== 'undefined' ?
                data?.rpmData?.PORT?.rpm.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('runningHours')">{{ typeof
                data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined' ?
                toHHMMSS(data?.rpmData?.PORT?.additional?.COUNTGT_dlrpRpm_1 * 60) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('volumeInventory')">{{ typeof
                data?.fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0 !== 'undefined' ?
                data?.fmData?.PORT_IN?.additional?.FIRST_dlfmVolInventory_0?.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('volumeInventory')">{{ typeof
                data?.fmData?.PORT_IN?.additional?.LAST_dlfmVolInventory_1 !== 'undefined' ?
                data?.fmData?.PORT_IN?.additional?.LAST_dlfmVolInventory_1?.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('volumeFlowrate')">{{ typeof data?.fmData?.PORT_IN?.volumeFlowrate
                !== 'undefined' ? data?.fmData?.PORT_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('temperature')">{{ typeof data?.fmData?.PORT_IN?.temperature !==
                'undefined' ? data?.fmData?.PORT_IN?.temperature.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.port.includes('density')">{{ typeof data?.fmData?.PORT_IN?.density !==
                'undefined' ? data?.fmData?.PORT_IN?.density.toFixed(2) : '-' }}</td>

              <td v-if="visibleColumns.starboard.includes('rpm')">{{ typeof data?.rpmData?.STARBOARD?.rpm !==
                'undefined' ? data?.rpmData?.STARBOARD?.rpm.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('runningHours')">{{ typeof
                data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 !== 'undefined' ?
                toHHMMSS(data?.rpmData?.STARBOARD?.additional?.COUNTGT_dlrpRpm_1 * 60) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('volumeInventory')">{{ typeof
                data?.fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0 !== 'undefined' ?
                data?.fmData?.STARBOARD_IN?.additional?.FIRST_dlfmVolInventory_0?.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('volumeInventory')">{{ typeof
                data?.fmData?.STARBOARD_IN?.additional?.LAST_dlfmVolInventory_1 !== 'undefined' ?
                data?.fmData?.STARBOARD_IN?.additional?.LAST_dlfmVolInventory_1?.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('volumeFlowrate')">{{ typeof
                data?.fmData?.STARBOARD_IN?.volumeFlowrate !== 'undefined' ?
                data?.fmData?.STARBOARD_IN?.volumeFlowrate.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('temperature')">{{ typeof
                data?.fmData?.STARBOARD_IN?.temperature !== 'undefined' ?
                data?.fmData?.STARBOARD_IN?.temperature.toFixed(2) : '-' }}</td>
              <td v-if="visibleColumns.starboard.includes('density')">{{ typeof data?.fmData?.STARBOARD_IN?.density !==
                'undefined' ? data?.fmData?.STARBOARD_IN?.density.toFixed(2) : '-' }}</td>

              <td v-if="visibleColumns.ae1.includes('runningHours')">{{ typeof data?.aeData?.AE1?.runningSeconds !==
                'undefined' ? toHHMMSS(data?.aeData?.AE1?.runningSeconds) : "-" }}</td>
              <td v-if="visibleColumns.ae1.includes('fuelConsumption')">{{ typeof data?.aeData?.AE1?.fuelConsumption !==
                'undefined' ? data?.aeData?.AE1?.fuelConsumption.toFixed(2) : "-" }}</td>

              <td v-if="visibleColumns.ae2.includes('runningHours')">{{ typeof data?.aeData?.AE2?.runningSeconds !==
                'undefined' ? toHHMMSS(data?.aeData?.AE2?.runningSeconds) : "-" }}</td>
              <td v-if="visibleColumns.ae2.includes('fuelConsumption')">{{ typeof data?.aeData?.AE2?.fuelConsumption !==
                'undefined' ? data?.aeData?.AE2?.fuelConsumption.toFixed(2) : "-" }}</td>
              <td v-if="visibleColumns.ae3.includes('runningHours')">{{ typeof data?.aeData?.AE3?.runningSeconds !==
                'undefined' ? toHHMMSS(data?.aeData?.AE3?.runningSeconds) : "-" }}</td>
              <td v-if="visibleColumns.ae3.includes('fuelConsumption')">{{ typeof data?.aeData?.AE3?.fuelConsumption !==
                'undefined' ? data?.aeData?.AE3?.fuelConsumption.toFixed(2) : "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="menuActive === 1" id="chart-content">
        <RpmFuelSpeed v-if="chartActive === 0" :series="currAssetSummary.series" />
        <PortFuelCharacteristics v-if="chartActive === 1" :series="currAssetSummary.series" />
        <StarboardFuelCharacteristics v-if="chartActive === 2" :series="currAssetSummary.series" />
        <AuxiliaryEngineUsage v-if="chartActive === 3" :series="currAssetSummary.series" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { toHHMMSS, generateTimestampsInSeconds } from '@/util/date';

import AuxiliaryEngineUsage from '@/components/charts/AuxiliaryEngineUsage';
import RpmFuelSpeed from '@/components/charts/RpmFuelSpeed';
import PortFuelCharacteristics from '@/components/charts/PortFuelCharacteristics';
import StarboardFuelCharacteristics from '@/components/charts/StarboardFuelCharacteristics';

import CloseMenuIcon from '@/assets/icons/close-menu-icon.svg';
import ChartLineIcon from '@/assets/icons/chart-line-icon.svg';
import ChartLineIconLight from '@/assets/icons/chart-line-icon-light.svg';
import TableIcon from '@/assets/icons/table-icon.svg';
import TableIconLight from '@/assets/icons/table-icon-light.svg';

export default {
  name: 'VoyageReportMenu',
  components: {
    AuxiliaryEngineUsage,
    RpmFuelSpeed,
    PortFuelCharacteristics,
    StarboardFuelCharacteristics,
  },
  data() {
    return {
      icon: {
        CloseMenuIcon,
        ChartLineIcon,
        ChartLineIconLight,
        TableIcon,
        TableIconLight,
      },
      menuActive: 0,
      chartActive: 0,
      unit: {
        speed: 'knot',
        distance: 'nm',
      },
      dataSort: 'oldest',
      visibleColumns: {
        gps: ['coordinate', 'heading', 'speed', 'cruise'],
        port: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        starboard: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        ae1: ['runningHours', 'fuelConsumption'],
        ae2: ['runningHours', 'fuelConsumption'],
        ae3: ['runningHours', 'fuelConsumption'],
      },
      availableColumns: {
        gps: ['coordinate', 'heading', 'speed', 'cruise'],
        port: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        starboard: ['rpm', 'runningHours', 'volumeInventory', 'volumeFlowrate', 'temperature', 'density'],
        ae1: ['runningHours', 'fuelConsumption'],
        ae2: ['runningHours', 'fuelConsumption'],
        ae3: ['runningHours', 'fuelConsumption'],
      },
    };
  },
  computed: {
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    visiblePortColumnsCount() {
      return Object.values(this.availableColumns.port)
        .filter((column) => this.visibleColumns.port.includes(column))
        .length;
    },
    visibleStarboardColumnsCount() {
      return Object.values(this.availableColumns.starboard)
        .filter((column) => this.visibleColumns.starboard.includes(column))
        .length;
    },
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};

      if (!summary) return null;

      const intervalSeconds = 60 * 60;

      let timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), intervalSeconds);

      timestamps = timestamps.map((t, i) => {
        let timestamp = t;
        if (i !== 0) timestamp = moment(timestamp * 1000).startOf('hour').unix();
        return timestamp;
      });

      const roundedGps = {};
      Object.keys(summary.gps?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf('hour').unix();
        roundedGps[roundedKey] = summary.gps?.data[key];
      });

      const roundedRpm = {};
      Object.keys(summary.rpm?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf('hour').unix();
        roundedRpm[roundedKey] = summary.rpm?.data[key];
      });

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf('hour').unix();
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const roundedAe = {};
      Object.keys(summary.ae?.data || {}).forEach((key, idx) => {
        let roundedKey = Math.round(key / 3600) * 3600;
        if (idx !== 0) roundedKey = moment(key * 1000).startOf('hour').unix();
        roundedAe[roundedKey] = summary.ae?.data[key];
      });

      let mappedSeries = timestamps.map((timestamp) => {
        const gpsData = roundedGps[timestamp] || {};
        const rpmData = roundedRpm[timestamp] || {
          STARBOARD: {},
          PORT: {},
        };
        const fmData = roundedFm[timestamp] || {};
        const aeData = roundedAe[timestamp] || {};
        return {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        };
      });

      if (this.dataSort === 'latest') {
        mappedSeries = mappedSeries.sort((a, b) => b.timestamp - a.timestamp);
      }

      if (this.dataSort === 'oldest') {
        mappedSeries = mappedSeries.sort((a, b) => a.timestamp - b.timestamp);
      }

      mappedSummary.series = mappedSeries;
      return mappedSummary;
    },
  },
  methods: {
    toHHMMSS,
    moment,
    convertDecimalToDMS(decimal, isLatitude) {
      const degrees = Math.floor(Math.abs(decimal));
      const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
      const minutes = Math.floor(minutesDecimal);
      const seconds = Math.round((minutesDecimal - minutes) * 60);

      // eslint-disable-next-line no-nested-ternary
      const direction = decimal >= 0
        ? (isLatitude ? 'N' : 'E')
        : (isLatitude ? 'S' : 'W');

      return `${degrees}° ${minutes}' ${seconds}'' ${direction}`;
    },
    showShowHideModal() {
      this.$bvModal.show('show-hide-column-modal');
    },
    hideShowHideModal() {
      this.$bvModal.hide('show-hide-column-modal');
    },
    areAllVisible(key) {
      return this.availableColumns[key].every((column) => this.visibleColumns[key].includes(column));
    },
    toggleAllColumns(key) {
      if (this.areAllVisible(key)) {
        this.visibleColumns[key] = [];
      } else {
        this.visibleColumns[key] = [...this.availableColumns[key]];
      }
    },
    onDownloadExcel() {

    },
  },
};
</script>

<style scoped>
#voyage-menu {
  height: 375px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #FFFFFF;
  z-index: 1000;
}

#menu-bar {
  height: 55px;
  background-color: #F2F4F4;
  display: flex;
}

#close-menu {
  cursor: pointer;
  color: #E5398C;
  border-right: 2px solid #E8ECF3;
  width: 55px;
  text-align: center;
  font-size: 23px;
  padding: 4px;
}

#menu-content {
  display: flex;
  flex-direction: row;
}

#content-selector {
  display: flex;
  flex-direction: column;
  min-width: 55px;
  max-width: 55px;
}

#chart-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E8ECF3;
  padding: 0;
}

.chart-selector {
  padding: 13.5px 15px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: #374948;
}

.active-chart-selector {
  background-color: #E5398C;
  color: white !important;
}

#chart-content {
  overflow-x: auto;
  width: 100%;
  height: calc(375px - 55px);
  position: relative;
}

.selector {
  cursor: pointer;
  border-bottom: 2px solid #E8ECF3;
  padding: 5px 10px;
  text-align: center;
}

.active-selector {
  background-color: #E5398C;
}

#datalog-content {
  overflow-x: auto;
  height: calc(375px - 55px);
  position: relative;
}

#datalog-content table {
  table-layout: auto;
  width: auto;
  border-collapse: separate;
  border-spacing: 0;
}

#datalog-content table thead {
  position: sticky;
  top: 0;
  z-index: 3;
}

#datalog-content table thead tr th {
  border: 1px solid rgb(234, 234, 234);
  padding: 4px 8px;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  color: #495057;
  background-color: #DEE2E6;
}

#datalog-content table tbody tr td {
  border: 1px solid rgb(234, 234, 234);
  padding: 8px 6px;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  color: #495057;
  background-color: #fefefe;
}

#datalog-content table tbody tr:nth-child(even) {
  background-color: #E9ECEF;
}

#datalog-content table th.sticky-column,
#datalog-content table td.sticky-column {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #E9ECEF;
}

#datalog-content table td.sticky-column {
  background-color: #fefefe;
}

#datalog-content table tr:nth-child(even) td.sticky-column {
  background-color: #fefefe;
}
</style>

<template>
  <ApexChart v-if="chartData && chartData.series" element="StarboardFuelCharacteristics" :chartOption="chartData"
    :series="chartData.series" />
</template>

<script>
import moment from 'moment';

export default {
  name: 'StarboardFuelCharacteristics',
  props: ['series'],
  computed: {
    chartData() {
      if (!this.series) return null;
      const isMobile = window.innerWidth <= 991;

      const MappedStarboardInFlow = this?.series?.map((data) => (data?.fmData?.STARBOARD_InFlow || 0)?.toFixed(2));
      const MappedStarboardRpm = this?.series?.map((data) => (data?.rpmData?.STARBOARD?.rpm || 0)?.toFixed(2));
      const MappedStarboardDens = this?.series?.map((data) => (data?.fmData?.STARBOARD_InDens || 0)?.toFixed(2));
      const MappedStarboardInTemp = this?.series?.map((data) => (data?.fmData?.STARBOARD_IN?.temperature || 0)?.toFixed(2));

      let MaxInFlow = Math.max(...MappedStarboardInFlow);
      // eslint-disable-next-line no-mixed-operators
      MaxInFlow += (MaxInFlow / 100 * 10);

      let MaxRpm = Math.max(...MappedStarboardRpm);
      MaxRpm = (Math.ceil(MaxRpm / 100) * 100) + 100;

      let MaxStarboardDens = Math.max(...MappedStarboardDens);
      // eslint-disable-next-line no-mixed-operators
      MaxStarboardDens += (MaxStarboardDens / 100 * 10);

      let MaxStarboardInTemp = Math.max(...MappedStarboardInTemp);
      // eslint-disable-next-line no-mixed-operators
      MaxStarboardInTemp += (MaxStarboardInTemp / 100 * 10);

      return {
        series: [
          {
            name: 'Starboard In Flow (L/h)',
            data: MappedStarboardInFlow,
            color: '#0084ff', // Biru cerah
          },
          {
            name: 'Starboard RPM',
            data: MappedStarboardRpm,
            color: '#884DFF', // Ungu
          },
          {
            name: 'Starboard In Density (g/cm³)',
            data: MappedStarboardDens,
            color: '#10B981', // Hijau tosca
          },
          {
            name: 'Starboard In Temp (°C)',
            data: MappedStarboardInTemp,
            color: '#F97316', // Oranye
          },
        ],
        chart: {
          height: 600,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        markers: {
          size: 3,
        },
        stroke: {
          width: 3,
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: this.series.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
          tickAmount: isMobile ? 3 : undefined,
        },
        yaxis: [
          {
            max: MaxInFlow,
            title: {
              text: 'Stbd. In Flow (L/h)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxRpm,
            title: {
              text: 'Stbd. RPM',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxStarboardDens,
            title: {
              text: 'Stbd. In Density (g/cm³)',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: MaxStarboardInTemp,
            title: {
              text: 'Stbd. In Temperature (°C)',
            },
            show: !isMobile,
          },
        ],
      };
    },
  },
  methods: {
  },
};
</script>

<style scoped></style>

<template>
  <div style="width: 100vw !important; margin:0 auto !important;">
    <data-filter :onApplyFilter="onApplyFilter" />

    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>

    <template v-else-if="currAssetSummary && !isLoading">
      <!-- Row One -->
      <div class="row-one">
        <iq-card>
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h6 class="card-title">{{ currAsset.massName }}</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <button class="badge badge-primary">See Details</button>
            </div>
          </div>
          <div class="iq-card-body">
            <img :src="currAsset.massImage !== '' ? '/api/' + currAsset.massImage : ''" :alt="currAsset.massImage"
              style="
                min-height: 200px;
                max-height: 200px;
                width: 100%;
                object-fit: cover;
                margin: 0 auto;
              ">
            <div class="row mt-2">
              <div class="col-4 ship-info">
                <span class="d-block ship-title">Type</span>
                <span class="d-block ship-subtitle">Tug Boat</span>
              </div>
              <div class="col-4 ship-info">
                <span class="d-block ship-title">Device Type</span>
                <span class="d-block ship-subtitle">{{ currAsset.massSystemType }}</span>
              </div>
              <!-- <div class="col-4 ship-info">
                <span class="d-block ship-title">Status</span>
                <div class="d-block ship-subtitle">
                  <span class="badge badge-success">On Hire</span>
                </div>
              </div> -->
            </div>
          </div>
        </iq-card>
        <div>
          <iq-card class="h-100 cruise-card">
            <h5>Today's Cruise</h5>
            <p class="text-muted">Last Data {{ currAssetSummary.gps.lastData }}</p>
            <div class="cruise-card-grid">
              <div style="background-color: #FFE2E5;">
                <img :src="img.cruiseDistanceIcon" alt="">
                <h5>{{ currAssetSummary.gps.totalDistance }} NM</h5>
                <p>Total Cruise</p>
                <!-- <span>+8% from yesterday</span> -->
              </div>
              <div style="background-color: #FFF4DE;">
                <img :src="img.cruiseSpeedIcon" alt="">
                <h5>{{ currAssetSummary.gps.averageSpeed }} Knot</h5>
                <p>Average Speed</p>
                <!-- <span>+5% from yesterday</span> -->
              </div>
              <div style="background-color: #DCFCE7;">
                <img :src="img.cruiseLocationIcon" alt="">
                <h5>{{ currAsset.location || '-' }}</h5>
                <p>Last Location</p>
                <!-- <span>Last update 23:00:00</span> -->
              </div>
            </div>
          </iq-card>
        </div>
      </div>
      <!-- ./ Row one -->

      <!-- Row Two -->
      <div class="row-two">
        <!-- Port Gauge -->
        <div class="iq-card">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h5 class="card-title">PORT</h5>
              <span class="text-muted m-sm-0">Last Updated {{ currAssetSummary.rpm.port.lastData }}</span>
            </div>
          </div>
          <div class="iq-card-body pt-0">
            <div class="row mt-2">
              <div class="col-7 p-0 rpm-chart-wrapper">
                <apexchart element="chart-14" :options="rpmChart"
                  :series="[((currAssetSummary?.rpm?.port?.lastRpmData.rpm / 1600) * 100).toFixed(2)]" />
              </div>
              <b-col md="5" sm="12" class="chart-info-wrapper">
                <div>
                  <p class="chart-title">Engine Hour</p>
                  <p class="chart-info"><b>{{ currAssetSummary.rpm.port.runningTime }}</b> Hour</p>
                </div>
                <div>
                  <p class="chart-title">Average RPM</p>
                  <p class="chart-info"><b>{{ currAssetSummary.rpm.port.average }}</b></p>
                </div>
                <!-- <div>
                  <p class="chart-title">Engine Hour</p>
                  <p class="chart-info-green"><b>Eco Speed</b></p>
                </div> -->
              </b-col>
            </div>
          </div>
        </div>
        <!-- ./ Port Gauge -->

        <!-- Starboard Gauge -->
        <div class="iq-card">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h5 class="card-title">Starboard</h5>
              <span class="text-muted m-sm-0">Last Updated {{ currAssetSummary.rpm.starboard.lastData }}</span>
            </div>
          </div>
          <div class="iq-card-body pt-0">
            <div class="row mt-2">
              <div class="col-7 p-0 rpm-chart-wrapper">
                <apexchart element="chart-14" :options="rpmChart"
                  :series="[((currAssetSummary?.rpm?.starboard?.lastRpmData?.rpm / 1600) * 100).toFixed(2)]" />
              </div>
              <b-col md="5" sm="12" class="chart-info-wrapper">
                <div>
                  <p class="chart-title">Engine Hour</p>
                  <p class="chart-info"><b>{{ currAssetSummary.rpm.port.runningTime }}</b> Hour</p>
                </div>
                <div>
                  <p class="chart-title">Average RPM</p>
                  <p class="chart-info"><b>{{ currAssetSummary.rpm.starboard.average }}</b></p>
                </div>
                <!-- <div>
                  <p class="chart-title">Engine Hour</p>
                  <p class="chart-info-green"><b>Eco Speed</b></p>
                </div> -->
              </b-col>
            </div>
          </div>
        </div>
        <!-- ./ Starboard Gauge -->

        <!-- Flowmeter Card -->
        <div class="iq-card">
          <div>
            <div style="display:flex; flex-direction:row; justify-content: space-between; padding: 10px 15px 0 15px;">
              <h4>Port Fuel Cons</h4>
              <h4>{{ currAssetSummary.fm.port.engineCons }} <span>Liter</span></h4>
            </div>
            <div style="display:grid; grid-template-columns: 0.45fr 1fr; gap: 10px; padding: 10px 15px 0 15px;">
              <div style="display: flex; flex-direction: column">
                <span>Avg Fuel Flow</span>
                <h4>{{ currAssetSummary.fm.port.avgFuelFlow }} <span>L/H</span></h4>
              </div>
              <ApexChart element="portFlowRateChart" :chartOption="currAssetSummary.portFlowRateChart"
                :series="currAssetSummary.portFlowRateChartSeries" />
            </div>
          </div>
          <hr class="my-0">
          <div>
            <div style="display:flex; flex-direction:row; justify-content: space-between; padding: 10px 15px 0 15px;">
              <h4>Starboard Fuel Cons</h4>
              <h4>{{ currAssetSummary.fm.starboard.engineCons }} <span>Liter</span></h4>
            </div>
            <div style="display:grid; grid-template-columns: 0.45fr 1fr; gap: 10px; padding: 10px 15px 0 15px;">
              <div style="display: flex; flex-direction: column">
                <span>Avg Fuel Flow</span>
                <h4>{{ currAssetSummary.fm.starboard.avgFuelFlow }} <span>L/H</span></h4>
              </div>
              <ApexChart element="starboardFlowRateChart" :chartOption="currAssetSummary.starboardFlowRateChart"
                :series="currAssetSummary.starboardFlowRateSeries" />
            </div>
          </div>
          <hr class="my-0">
          <div>
            <div style="padding: 10px 15px 0 15px;">
              <h4>Total Engine Cons</h4>
            </div>
            <div style="display:flex; flex-direction: row; justify-content: space-between; padding: 10px 15px 0 15px;">
              <div style="display: flex; flex-direction: column">
                <span>Fuel Cons</span>
                <h4>{{ currAssetSummary.fm.meEngineCons }} <span>Liter</span></h4>
              </div>
              <div style="display: flex; flex-direction: column">
                <span>Avg Flow</span>
                <h4>{{ (parseInt(currAssetSummary.fm.port.avgFuelFlow) +
                  parseInt(currAssetSummary.fm.starboard.avgFuelFlow)) / 2 }} <span>L/H</span></h4>
              </div>
            </div>
          </div>
        </div>
        <!-- ./ Flowmeter Card -->
      </div>
      <!-- ./ Row -->

      <!-- Row three -->
      <div class="row-three mt-2">
        <div class="ae-column py-0 mt-0 mx-0 px-0">
          <div class="iq-card ae-card">
            <div class="iq-card-body">
              <b-row>
                <b-col lg="12">
                  <!-- <span class="badge badge-success float-right ae-status">ON</span> -->
                  <div class="d-flex justify-content-between">
                    <h4 class="ae-title">Auxiliary Engine 1</h4>
                    <span class="badge badge-success" v-if="currAssetSummary.ae.ae1.lastRunningTime > 0">ON</span>
                    <span class="badge badge-danger" v-else>OFF</span>
                  </div>
                  <p class="ae-hour">{{ currAssetSummary.ae.ae1.runningSeconds }} <span
                      class="text-muted ae-unit">hour</span></p>
                  <!-- <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
                    <b-progress :value="100" class="iq-progress-bar" variant="primary"></b-progress>
                  </div> -->
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="iq-card ae-card">
            <div class="iq-card-body">
              <b-row>
                <b-col lg="12">
                  <!-- <span class="badge badge-danger float-right ae-status">Off</span> -->
                  <div class="d-flex justify-content-between">
                    <h4 class="ae-title">Auxiliary Engine 2</h4>
                    <span class="badge badge-success" v-if="currAssetSummary.ae.ae2.lastRunningTime > 0">ON</span>
                    <span class="badge badge-danger" v-else>OFF</span>
                  </div>
                  <p class="ae-hour">{{ currAssetSummary.ae.ae2.runningSeconds }} <span
                      class="text-muted ae-unit">hour</span></p>
                  <!-- <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
                    <b-progress :value="100" class="iq-progress-bar" variant="primary"></b-progress>
                  </div> -->
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="iq-card ae-card">
            <div class="iq-card-body">
              <b-row>
                <b-col lg="12">
                  <!-- <span class="badge badge-danger float-right ae-status">Off</span> -->
                  <div class="d-flex justify-content-between">
                    <h4 class="ae-title">Auxiliary Engine 2</h4>
                    <span class="badge badge-success" v-if="currAssetSummary.ae.ae3.lastRunningTime > 0">ON</span>
                    <span class="badge badge-danger" v-else>OFF</span>
                  </div>
                  <p class="ae-hour">{{ currAssetSummary.ae.ae3.runningSeconds }} <span
                      class="text-muted ae-unit">hour</span></p>
                  <!-- <div class="iq-progress-bar-linear d-inline-block w-100 float-left mt-3">
                    <b-progress :value="100" class="iq-progress-bar" variant="primary"></b-progress>
                  </div> -->
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <!-- ./ Row three -->

      <!-- Row four -->
      <div class="row-four">
        <div class="map-section">
          <Map v-if="currAsset" :ships="[currAsset]" SelectedMap="default" :center="center" :zoom="zoom"
            style="border-radius: 20px; margin-top: 10px; max-height: 100%;"
            :Options="{ enableTrail: true }" />
        </div>
        <!-- NOTE: temporary hide percentage -->
        <!-- <div class="device-status">
          <div class="iq-card device-card">
            <p>RPM</p>
            <h5 class="good">{{ currAssetSummary.rpm.percentage }}%</h5>
            <span>Updated {{ currAssetSummary.rpm.port.lastData || currAssetSummary.rpm.starboard.lastData }}</span>
          </div>
          <div class="iq-card device-card">
            <p>GPS</p>
            <h5 class="good">{{ currAssetSummary.gps.percentage }}%</h5>
            <span>Updated {{ currAssetSummary.gps.lastData }}</span>
          </div>
          <div class="iq-card device-card">
            <p>AE</p>
            <h5 class="good">{{ currAssetSummary.ae.percentage }}%</h5>
            <span>Updated {{ currAssetSummary.ae.lastData }}</span>
          </div>
          <div class="iq-card device-card">
            <p>Flowmeter</p>
            <h5 class="good">{{ currAssetSummary.fm.percentage }}%</h5>
            <span>Updated {{ currAssetSummary.gps.lastData }}</span>
          </div>
        </div> -->
      </div>
      <!-- ./ Row four -->

      <!-- Row five -->
      <div class="row-five">
        <div class="iq-card ">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h6 class="card-title">RPM, Fuel Consumption, And Speed Chart</h6>
            </div>
          </div>
          <div class="card-info-container">
            <div class="card-info-section">
              <h5 class="">{{ currAssetSummary.rpm.port.average }}</h5>
              <span class="">Avg. Port RPM</span>
            </div>
            <div class="card-info-section">
              <h5 class="">{{ currAssetSummary.fm.port.engineCons }} Liter</h5>
              <span class="">Port Fuel Cons</span>
            </div>
            <div class="card-info-section">
              <h5 class="">{{ currAssetSummary.rpm.starboard.average }}</h5>
              <span class="">Avg. Stbd. RPM</span>
            </div>
            <div class="card-info-section">
              <h5 class="">{{ currAssetSummary.fm.starboard.engineCons }} Liter</h5>
              <span class="">Stbd. Fuel Cons</span>
            </div>
            <div class="card-info-section">
              <h5 class="">{{ currAssetSummary.gps.averageSpeed }} Knot</h5>
              <span class="">Avg. Speed</span>
            </div>
          </div>

          <rpm-fuel-speed :series="currAssetSummary.series" />
        </div>
        <!-- <div class="iq-card engine-chart">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h6 class="card-title">Engine Usage Chart</h6>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <p class="text-muted">Last Updated 15 Juli 2024, 12:54</p>
            </div>
          </div>
          <div style="padding: 0 -50px">
            <ApexChart element="Dash1DonetChart" :chartOption="engineChart" :series="[44, 55, 41]" />
          </div>
        </div> -->
      </div>
      <!-- ./ Row five -->

      <!-- Row six -->
      <!-- <div class="row row-six mb-5">
        <div class="col-sm-12 col-md-6">
          <div class="iq-card">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h6 class="card-title">Port Average Fuel Cons Based on RPM</h6>
              </div>
            </div>

            <div class="iq-card-body">
              <ApexChart element="portAvgFuel" :chartOption="portAvgFuelChart" :series="portAvgFuelSeries" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="iq-card">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h6 class="card-title">Starbaord Average Fuel Cons Based on RPM</h6>
              </div>
            </div>

            <div class="iq-card-body">
              <ApexChart element="starboardAvgFuel" :chartOption="starboardAvgFuelChart"
                :series="starboardAvgFuelSeries" />
            </div>
          </div>
        </div>
      </div> -->
      <!-- ./ Row six -->
    </template>

    <template v-else-if="!currAssetSummary">
      <div class="text-center mt-5">
        <h5>No Data</h5>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import vesselImage from '@/assets/images/vessel.jpeg';
import { mapState, mapActions } from 'vuex';
import Map from '@/views/map';
import DataFilter from '@/components/DataFilter';
import RpmFuelSpeed from '@/components/charts/RpmFuelSpeed';
import { toHHMMSS, generateTimestampsInSeconds } from '@/util/date';

import cruiseDistanceIcon from '@/assets/images/dashboard/cruise-distance.svg';
import cruiseLocationIcon from '@/assets/images/dashboard/cruise-location.svg';
import cruiseSpeedIcon from '@/assets/images/dashboard/cruise-speed.svg';

export default {
  name: 'FmsDashboard',
  components: {
    Map,
    DataFilter,
    RpmFuelSpeed,
  },
  data() {
    const engineChart = {
      chart: {
        height: 300,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '40%',
          },
        },
      },
      series: [44, 55, 41],
      colors: ['#F94144', '#0095FF', '#00E096'],
      labels: ['700-800', '800-900', '900-1000'],
      legend: {
        position: 'bottom',
      },
    };

    const rpmChart = {
      chart: {
        width: '100%',
        height: '100%',
        type: 'radialBar',
      },
      plotOptions: {
        hollow: {
          margin: 5,
          size: '50%',
          background: 'transparent',
          image: undefined,
          imageWidth: 64,
          imageHeight: 64,
        },
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '14px',
              color: undefined,
              offsetY: 110,
            },
            value: {
              offsetY: 70,
              fontSize: '19px',
              color: undefined,
              formatter(val) {
                return ((val / 100) * 1600).toFixed(2);
              },
            },
          },
        },
      },
      fill: {},
      stroke: {
        dashArray: 4,
      },
      labels: ['Current RPM'],
      colors: ['#0084ff'],
    };

    const portAvgFuelSeries = [{
      data: [{
        x: 600,
        y: 13.3,
      }, {
        x: 700,
        y: 24.2,
      }, {
        x: 800,
        y: 33,
      }, {
        x: 900,
        y: 45,
      }, {
        x: 1000,
        y: 55,
      }, {
        x: 1100,
        y: 60,
      }, {
        x: 1200,
        y: 88,
      }],
    }];
    const portAvgFuelChart = {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      series: portAvgFuelSeries,
    };

    const starboardAvgFuelSeries = [{
      data: [{
        x: 600,
        y: 13.3,
      }, {
        x: 700,
        y: 24.2,
      }, {
        x: 800,
        y: 33,
      }, {
        x: 900,
        y: 45,
      }, {
        x: 1000,
        y: 55,
      }, {
        x: 1100,
        y: 60,
      }, {
        x: 1200,
        y: 88,
      }],
    }];
    const starboardAvgFuelChart = {
      chart: {
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      series: starboardAvgFuelSeries,
    };

    return {
      // eslint-disable-next-line no-undef
      center: L.LatLng(0, 0),
      zoom: 10,
      img: {
        cruiseDistanceIcon,
        cruiseLocationIcon,
        cruiseSpeedIcon,
      },
      vesselImage,
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
      rpmChart,
      engineChart,
      portAvgFuelSeries,
      portAvgFuelChart,
      starboardAvgFuelSeries,
      starboardAvgFuelChart,
      isLoading: true,
    };
  },
  async mounted() {
    const interval = this.$store.getters['filter/interval'];
    if (interval === 'minute') {
      this.$store.commit('filter/SET_INTERVAL', 'hour');
    }
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });

    this.isLoading = false;
  },
  computed: {
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    currAssetSummary() {
      const summary = this.currAsset?.summary || null;
      const mappedSummary = {};

      if (!summary) return null;

      const interval = this.$store.getters['filter/interval'];
      // eslint-disable-next-line no-nested-ternary
      let intervalSeconds = 60;

      switch (interval) {
        case 'minute':
          intervalSeconds = 60;
          break;
        case 'hour':
          intervalSeconds = 60 * 60;
          break;
        case 'day':
          intervalSeconds = 60 * 60 * 24;
          break;
        case 'week':
          intervalSeconds = 60 * 60 * 24 * 7;
          break;
        default:
          break;
      }

      const unixRangeStart = moment(this.dateRange.start).unix();
      const unixRangeEnd = moment(this.dateRange.end).unix();

      const intervalCount = Math.ceil((unixRangeEnd - unixRangeStart) / intervalSeconds);

      // GPS Mapping
      const lastGpsData = Object.values(summary.gps?.data || [{}])[Object.values(summary.gps?.data || [{}]).length - 1];
      const gpsCount = Object.values(summary.gps?.data || []).length;

      mappedSummary.gps = {
        lastGpsData,
        averageSpeed: (summary.gps?.summary?.averageSpeed * 0.5399568034557236).toFixed(2),
        totalDistance: (summary.gps?.summary?.totalDistance / 1852).toFixed(2),
        lastData: lastGpsData ? moment(lastGpsData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
        percentage: ((gpsCount / intervalCount) * 100).toFixed(1),
      };
      // end of GPS Mapping

      // RPM Mapping
      const lastPortRpmData = Object.values(summary.rpm?.data || []).length > 0
        ? Object.values(summary.rpm?.data)[Object.values(summary.rpm?.data).length - 1].PORT
        : {};
      const lastStarboardRpmData = Object.values(summary.rpm?.data || []).length > 0
        ? Object.values(summary.rpm?.data)[Object.values(summary.rpm?.data).length - 1].STARBOARD
        : {};

      const rpmCount = Object.values(summary.rpm?.data || []).length;

      mappedSummary.rpm = {
        port: {
          lastData: lastPortRpmData ? moment(lastPortRpmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.rpm?.summary?.runningTime?.PORT || 0),
          average: (summary.rpm?.summary?.average?.PORT || 0).toFixed(2),
          lastRpmData: lastPortRpmData,
        },
        starboard: {
          lastData: lastStarboardRpmData ? moment(lastStarboardRpmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          runningTime: toHHMMSS(summary.rpm?.summary?.runningTime?.STARBOARD || 0),
          average: (summary.rpm?.summary?.average?.STARBOARD || 0).toFixed(2),
          lastRpmData: lastStarboardRpmData,
        },
        percentage: ((rpmCount / intervalCount) * 100).toFixed(1),
      };
      // end of RPM Mapping

      // FM Mapping
      const lastPortFmData = Object.values(summary?.flowmeter?.data || []).length > 0
        ? Object.values(summary.flowmeter.data)[Object.values(summary.flowmeter.data).length - 1]?.PORT
        : {};
      const lastStarboardFmData = Object.values(summary?.flowmeter?.data || []).length > 0
        ? Object.values(summary.flowmeter.data)[Object.values(summary.flowmeter.data).length - 1]?.STARBOARD
        : {};

      const fmCount = Object.values(summary?.flowmeter?.data || []).length;

      mappedSummary.fm = {
        port: {
          avgFuelFlow: (summary.flowmeter?.summary?.portAverageFlow || 0).toFixed(2),
          engineCons: (summary.flowmeter?.summary?.portEngineCons || 0).toFixed(2),
          lastData: lastPortFmData ? moment(lastPortFmData.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          lastFmData: lastPortFmData,
        },
        starboard: {
          avgFuelFlow: (summary.flowmeter?.summary?.starboardAverageFlow || 0).toFixed(2),
          engineCons: (summary.flowmeter?.summary?.starboardEngineCons || 0).toFixed(2),
          lastData: lastStarboardFmData ? moment(lastStarboardFmData?.timestamp * 1000).format('DD MMMM YYYY HH:mm') : '-',
          lastFmData: lastStarboardFmData,
        },
        meEngineCons: (summary.flowmeter?.summary?.meEngineCons || 0).toFixed(2),
        percentage: ((fmCount / intervalCount) * 100).toFixed(1),
      };
      // end of FM Mapping

      // AE Mapping
      const lastAeData = Object.values(summary?.ae?.data || []).length > 0
        ? Object.values(summary?.ae?.data)[Object.values(summary.ae?.data).length - 1]
        : null;
      const lastAeKey = Object.keys(summary?.ae?.data || []).length > 0
        ? Object.keys(summary?.ae?.data)[Object.keys(summary.ae?.data || [{}]).length - 1]
        : null;

      const aeCount = Object.keys(summary?.ae?.data || []).length;

      mappedSummary.ae = {
        ae1: {
          fuelConsumption: summary.ae?.summary?.fuelConsumption?.AE1 || '-',
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE1 || 0),
          lastRunningTime: lastAeData?.AE1.runningTime || 0,
        },
        ae2: {
          fuelConsumption: summary.ae?.summary?.fuelConsumption?.AE2 || '-',
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE2 || 0),
          lastRunningTime: lastAeData?.AE2.runningTime || 0,
        },
        ae3: {
          fuelConsumption: summary.ae?.summary?.fuelConsumption?.AE3 || '-',
          runningSeconds: toHHMMSS(summary.ae?.summary?.runningSeconds?.AE3 || 0),
          lastRunningTime: lastAeData?.AE3.runningTime || 0,
        },
        lastAeData,
        lastData: lastAeKey ? moment(lastAeKey * 1000).format('DD MMMM YYYY HH:mm') : '-',
        percentage: ((aeCount / intervalCount) * 100).toFixed(1),
      };
      // end of AE Mapping

      const timestamps = generateTimestampsInSeconds(moment(this.dateRange.start).unix(), moment(this.dateRange.end).unix(), intervalSeconds);

      const roundedGps = {};
      Object.keys(summary.gps?.data || {}).forEach((key) => {
        const roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedGps[roundedKey] = summary.gps?.data[key];
      });

      const roundedRpm = {};
      Object.keys(summary.rpm?.data || {}).forEach((key) => {
        const roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedRpm[roundedKey] = summary.rpm?.data[key];
      });

      const roundedFm = {};
      Object.keys(summary.flowmeter?.data || {}).forEach((key) => {
        const roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedFm[roundedKey] = summary.flowmeter?.data[key];
      });

      const roundedAe = {};
      Object.keys(summary.ae?.data || {}).forEach((key) => {
        const roundedKey = moment(key * 1000).startOf(this.interval).unix();
        roundedAe[roundedKey] = summary.ae?.data[key];
      });

      const mappedSeries = timestamps.map((timestamp) => {
        const gpsData = roundedGps[timestamp] || {};
        const rpmData = roundedRpm[timestamp] || {
          STARBOARD: {},
          PORT: {},
        };
        const fmData = roundedFm[timestamp] || {};
        const aeData = roundedAe[timestamp] || {};
        return {
          timestamp,
          gpsData,
          rpmData,
          fmData,
          aeData,
        };
      });

      mappedSummary.series = mappedSeries;

      mappedSummary.portFlowRateChartSeries = [{
        name: 'Port Flow Rate',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(-60).map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2))
          : mappedSeries.map((data) => (data.fmData.PORT_InFlow || 0).toFixed(2)),
      }];
      mappedSummary.portFlowRateChart = {
        series: mappedSummary.portFlowRateChartSeries,
        chart: {
          height: 60,
          width: '100%',
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        colors: ['#0084FF'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      mappedSummary.starboardFlowRateSeries = [{
        name: 'Starboard Flow Rate',
        data: mappedSeries.length > 60
          ? mappedSeries.slice(mappedSeries.length - 60).map((data) => (data.fmData.STARBOARD_InFlow || 0).toFixed(2))
          : mappedSeries.map((data) => (data.fmData.STARBOARD_InFlow || 0).toFixed(2)),
      }];
      mappedSummary.starboardFlowRateChart = {
        series: mappedSummary.starboardFlowRateSeries,
        chart: {
          height: 60,
          width: '100%',
          type: 'area',
          sparkline: {
            enabled: true,
          },
          group: 'sparklines',

        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        colors: ['#00CA00'],
        xaxis: {
          type: 'datetime',
          categories: mappedSeries.length > 60
            ? mappedSeries.slice(mappedSeries.length - 60).map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm'))
            : mappedSeries.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
      };

      return mappedSummary;
    },
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
    }),
    selectedInterval: {
      get() {
        return this.interval;
      },
      set(val) {
        this.$store.dispatch('filter/setInterval', val);
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
  },
  methods: {
    ...mapActions(['updatekapalTrail']),
    zoomToShip(ship) {
      // eslint-disable-next-line no-undef
      this.center = L.LatLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
    async onApplyFilter() {
      this.isLoading = true;
      try {
        // await this.$store.dispatch('filter/setDate', this.dateRange);
        await this.$store.dispatch('filter/setCurrentMassId', this.selectedAsset || this.currentMassId);
        await this.$store.dispatch('filter/setInterval', this.selectedInterval || this.interval);

        await this.$store.dispatch('asset/fetchSummary', {
          start: this.dateRange.start,
          end: this.dateRange.end,
          interval: this.interval,
          massId: this.currentMassId,
        });
      } catch (err) {
        console.err(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.iq-card-header-toolbar p {
  margin: 0;
}

.row-one {
  display: grid;
  grid-template-columns: 484px 1fr;
  grid-gap: 5px;
  padding: 0 10px;
  margin-top: 10px;
}

@media screen and (max-width: 991px) {
  .row-one {
    display: block;
  }

  .row-one div {
    max-width: 100vw !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.ship-info .ship-title {
  font-size: 1rem;
  font-weight: 500;
}

.ship-info .ship-subtitle {
  font-size: 1rem;
  font-weight: 700;
}

.ship-info .ship-subtitle span {
  font-size: 12px;
  font-weight: 700;
}

.cruise-card {
  padding: 5px 31px;
  overflow: hidden;
}

.cruise-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 28px;
  min-height: 230px;
}

.cruise-card-grid div {
  height: 100%;
  border-radius: 16px;
  padding: 20px;
}

.cruise-card-grid div h5 {
  font-size: 1.8em;
  margin-top: 15px;
}

.cruise-card-grid div p {
  font-size: 16px;
  margin-bottom: 8px;
}

.cruise-card-grid div span {
  font-size: 12px;
  color: #4079ed;
}

@media screen and (max-width: 991px) {
  .cruise-card {
    padding: 5px 10px;
  }

  .cruise-card-grid {
    grid-template-columns: 1fr;
    grid-gap: 5px;
    margin-top: 5px;
    min-height: 0;
  }

  .cruise-card-grid div {
    height: 100%;
    border-radius: 16px;
    padding: 10px 15px;
  }

  .cruise-card-grid div img {
    width: 2rem;
  }

  .cruise-card-grid div h5 {
    font-size: 1.1em;
    margin-top: 5px;
  }

  .cruise-card-grid div p {
    font-size: 0.9em;
    margin-bottom: 4px;
  }

  .cruise-card-grid div span {
    font-size: 10px;
    color: #4079ed;
  }
}

.row-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  padding: 0 10px;
  margin-top: 10px;
}

.chart-title {
  font-size: 16px;
  color: #374948;
  margin: 0;
}

.chart-info {
  font-size: 25px;
  color: #0084ff;
  margin: 0;
}

.chart-info-green {
  font-size: 25px;
  color: #55c95a;
  margin: 0;
}

@media screen and (max-width: 991px) {
  .row-two {
    display: block;
    padding: 0 10px;
    margin-top: 10px;
  }

  .row-two .iq-card {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .rpm-chart-wrapper {
    display: none;
  }

  .chart-title {
    font-size: 12px;
    color: #374948;
    margin: 0;
  }

  .chart-info-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    padding-left: 20px
  }

  .chart-info {
    font-size: 20px;
    color: #0084ff;
  }

  .chart-info-green {
    font-size: 20px;
    color: #55c95a;
  }
}

.row-three {
  display: flex;
  padding: 0 10px;
}

.ae-column {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  padding: 0 10px;
  margin-top: 10px;
}

.ae-card {
  padding: 15px 10px;
}

.ae-card .iq-card-body {
  padding: 0;
}

.ae-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.ae-hour {
  font-size: 25px;
  font-weight: 1000;
  color: black;
  margin: 0;
  line-height: 30px;
}

.ae-unit {
  font-size: 16px;
}

.iq-progress-bar-linear {
  margin-top: 5px !important;
}

@media screen and (max-width: 991px) {
  .ae-column {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  }

  .ae-card {
    padding: 15px 10px;
  }

  .ae-card .iq-card-body {
    padding: 0;
  }

  .ae-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  .ae-hour {
    font-size: 25px;
    font-weight: 1000;
    color: black;
    margin: 0;
    line-height: 30px;
  }

  .ae-unit {
    font-size: 16px;
  }

  .iq-progress-bar-linear {
    margin-top: 5px !important;
  }
}

.row-four {
  display: grid;
  /* NOTE: temporary hide percentage */
  /* grid-template-columns: 70% 1fr; */
  grid-template-columns: 1fr;
  min-height: 454px;
  grid-gap: 10px;
  padding: 0 10px;
}

.device-status {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  padding: 5px 5px;
  margin-top: 10px;
}

.device-card {
  height: 100%;
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crcl {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.device-card p {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 24px 0px 0px 0px;
}

.device-card h5 {
  font-size: 31px;
  line-height: 41px;
  text-align: center;
  font-weight: 700;
}

.device-card h5.good {
  color: #00ca00;
}

.device-card h5.warn {
  color: #00ca00;
}

.device-card span {
  font-size: 12px;
  line-height: 20px;
  margin: 0 auto;
}

.row-five {
  display: grid;
  /* grid-template-columns: 70% 1fr; */
  grid-template-columns: 1fr;
  grid-gap: 5px;
  padding: 5px 5px;
  margin-top: 20px;
}

.engine-chart {
  margin-bottom: auto !important;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .row-four {
    display: grid;
    grid-template-columns: 1fr;
    min-height: 454px;
    grid-gap: 10px;
    padding: 0 10px;
  }

  .row-four .map-section {
    min-height: 454px;
  }

  .device-status {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    padding: 5px 5px;
    margin-top: 10px;
  }

  .device-card {
    height: 100%;
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
  }

  .crcl {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .device-card p {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin: 24px 0px 0px 0px;
  }

  .device-card h5 {
    font-size: 31px;
    line-height: 41px;
    text-align: center;
    font-weight: 700;
  }

  .device-card h5.good {
    color: #00ca00;
  }

  .device-card h5.warn {
    color: #00ca00;
  }

  .device-card span {
    font-size: 12px;
    line-height: 20px;
    margin: 0 auto;
  }

  .row-five {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    padding: 5px 5px;
    margin-top: 20px;
  }

  .engine-chart {
    margin-bottom: auto !important;
    height: 100%;
  }
}

.card-info-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  padding: 10px 15px;
}

.card-info-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .card-info-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: auto auto;
  }
}

</style>

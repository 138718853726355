<template>
  <div id="voyage-sidebar">
    <div id="voyage-data-filter" class="d-sm-block d-md-flex d-lg-flex align-items-center pb-2">
      <!-- date filter -->
      <div class="mx-2">
        <span class="d-md-block text-muted small text-left font-weight-bold">Select Range</span>
        <v-date-picker v-model="dateRange" is-range mode="datetime" :masks="masks" is24hr>
          <template #default="{ inputValue, inputEvents, isDragging }">
            <div class="d-flex flex-column flex-sm-row justify-content-start align-items-center">
              <input class="form-control bg-light border rounded date-input"
                :class="isDragging ? 'text-muted' : 'text-dark'" :value="inputValue.start + ' - ' + inputValue.end"
                v-on="inputEvents.start" />
            </div>
          </template>
        </v-date-picker>
      </div>

      <div class="mx-2">
        <span class="d-md-block text-muted small text-left font-weight-bold">Asset</span>
        <select name="" class="form-control" v-model="selectedAsset">
          <option v-for="asset in assets" :key="asset.massId" :value="asset.massId">{{ asset.massName }}</option>
        </select>
      </div>

      <div class="mx-2">
        <span class="d-sm-none d-md-block text-muted small text-left font-weight-bold">&nbsp;</span>
        <button class="btn btn-mantraz btn-apply" @click="onApplyFilter">Apply</button>
      </div>
    </div>

    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>

    <template v-else-if="!isLoading && currAsset && currAsset.summary">
      <div id="voyage-asset-summary">
        <div id="voyage-asset-info">
          <h5 id="voyage-asset-info-name">{{ currAsset.massName }}</h5>
          <img id="voyage-asset-info-img" :src="currAsset.massImage !== '' ? '/api/' + currAsset.massImage : ''" :alt="currAsset.massName + 'Image'">
          <div id="voyage-asset-info-data">
            <div class="voyage-asset-info-data-item">
              <h6 class="voyage-asset-info-data-item-title">Type</h6>
              <p class="voyage-asset-info-data-item-value">{{ currAsset?.massMaty?.matyName }}</p>
            </div>

            <div class="voyage-asset-info-data-item">
              <h6 class="voyage-asset-info-data-item-title">Device Type</h6>
              <p class="voyage-asset-info-data-item-value">{{ currAsset?.massSystemType }}</p>
            </div>

            <!-- <div class="voyage-asset-info-data-item">
              <h6 class="voyage-asset-info-data-item-title">Status</h6>
              <span class="onhire-badge">ON HIRE</span>
              <span class="offhire-badge">OFF HIRE</span>
            </div> -->
          </div>
        </div>

        <div id="voyage-asset-accumulations">
          <div class="voyage-asset-accumulation-card">
            <h4 class="voyage-asset-accumulation-card-data">{{ (currAsset?.summary?.gps?.summary?.distance / 1852).toFixed(2) }} NM</h4>
            <span class="voyage-asset-accumulation-card-title">Total Cruise</span>
          </div>

          <div class="voyage-asset-accumulation-card">
            <h4 class="voyage-asset-accumulation-card-data">{{ (currAsset?.summary?.gps?.summary?.averageSpeed * 0.5399568034557236).toFixed(2) }} KNOT</h4>
            <span class="voyage-asset-accumulation-card-title">Average Speed</span>
          </div>

          <div class="voyage-asset-accumulation-card">
            <h4 class="voyage-asset-accumulation-card-data">{{ (currAsset?.summary?.flowmeter?.summary?.meEngineCons).toFixed(2) }} LITER</h4>
            <span class="voyage-asset-accumulation-card-title">Total Engine Cons</span>
          </div>
        </div>
      </div>

      <div id="voyage-vessel-summary">
        <div id="voyage-vessel-card">
          <div id="voyage-vessel-header">
            <h4>Vessel Summary</h4>
            <span>Last Updated {{ moment(lastGpsData?.timestamp * 1000).format('DD MMMM YYYY, HH:mm') }}</span>
          </div>

          <hr />

          <div id="voyage-vessel-location">
            <div id="voyage-vessel-first-location">
              <h5 class="voyage-vessel-latlong">{{ convertLatLngToDMS(firstGpsData?.latitude, firstGpsData?.longitude) }}</h5>
              <span class="voyage-vessel-date">{{ moment(firstGpsData?.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>

            <div id="voyage-vessel-duration">
              <span>{{toHHMMSS(lastGpsData?.timestamp  - firstGpsData?.timestamp )}} Hours</span>
              <hr style="border-top: 2px solid #000000;" />
              <span>Duration</span>
            </div>

            <div id="voyage-vessel-last-location">
              <h5 class="voyage-vessel-latlong">{{ convertLatLngToDMS(lastGpsData?.latitude, lastGpsData?.longitude) }}</h5>
              <span class="voyage-vessel-date">{{ moment(lastGpsData?.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
          </div>

          <hr />

          <div class="voyage-vessel-container">
            <div class="voyage-vessel-column voyage-left-column">
              <h4>Port Engine</h4>
              <div>
                <h6 class="voyage-vessel-data-title">Average RPM</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.rpm?.summary?.average?.PORT?.toFixed(2) }}
                </h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Engine Running</h6>
                <h4 class="voyage-vessel-data-value">{{ toHHMMSS(currAsset?.summary?.rpm?.summary?.runningTime?.PORT) }}</h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Fuel Cons</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.flowmeter?.summary?.portEngineCons?.toFixed(2) }} Liter</h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Fuel Flowrate</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.flowmeter?.summary?.portAverageFlow?.toFixed(2) }} L/H</h4>
              </div>
            </div>
            <div class="voyage-vessel-column voyage-right-column">
              <h4>Starboard Engine</h4>
              <div>
                <h6 class="voyage-vessel-data-title">Average RPM</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.rpm?.summary?.average?.STARBOARD?.toFixed(2)
                  }}</h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Engine Running</h6>
                <h4 class="voyage-vessel-data-value">{{ toHHMMSS(currAsset?.summary?.rpm?.summary?.runningTime?.STARBOARD) }}</h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Fuel Cons</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.flowmeter?.summary?.starboardEngineCons?.toFixed(2) }}  Liter</h4>
              </div>
              <div>
                <h6 class="voyage-vessel-data-title">Fuel Flowrate</h6>
                <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.flowmeter?.summary?.starboardAverageFlow?.toFixed(2) }} L/H</h4>
              </div>
            </div>
          </div>
          <hr style="border-top: 2px solid #E8ECF3;" />

          <div id="voyage-vessel-ae">
            <div>
              <h6 class="voyage-vessel-data-title">Auxiliary Engine 1</h6>
              <h4 class="voyage-vessel-data-value">{{ toHHMMSS(currAsset?.summary?.ae?.summary?.runningSeconds?.AE1) }} Hour</h4>
              <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.ae?.summary?.fuelConsumption?.AE1?.toFixed(2) }} Liter</h4>
            </div>

            <div>
              <h6 class="voyage-vessel-data-title">Auxiliary Engine 2</h6>
              <h4 class="voyage-vessel-data-value">{{ toHHMMSS(currAsset?.summary?.ae?.summary?.runningSeconds?.AE2) }} Hour</h4>
              <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.ae?.summary?.fuelConsumption?.AE2?.toFixed(2) }} Liter</h4>
            </div>

            <div>
              <h6 class="voyage-vessel-data-title">Auxiliary Engine 3</h6>
              <h4 class="voyage-vessel-data-value">{{ toHHMMSS(currAsset?.summary?.ae?.summary?.runningSeconds?.AE3) }} Hour</h4>
              <h4 class="voyage-vessel-data-value">{{ currAsset?.summary?.ae?.summary?.fuelConsumption?.AE3?.toFixed(2) }} Liter</h4>
            </div>
          </div>

          <hr />
          <div id="view-datalog-button" @click="() => $emit('toggleMenu')">
            <h6>View Datalog</h6>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { toHHMMSS } from '@/util/date';

export default {
  name: 'VoyageSidebar',
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      masks: {
        input: 'DD-MM-YYYY HH:mm',
      },
    };
  },
  computed: {
    dateRange: {
      get() {
        const { start, end } = this.$store.getters['filter/date'];
        const startDate = new Date(moment(start).valueOf());
        const endDate = new Date(moment(end).valueOf());

        return { start: startDate, end: endDate };
      },
      set(val) {
        const start = moment(val.start);
        const end = moment(val.end);

        this.$store.commit('filter/SET_DATE', { start, end });
      },
    },
    selectedAsset: {
      get() {
        return this.currentMassId;
      },
      set(val) {
        this.$store.dispatch('filter/setCurrentMassId', val);
      },
    },
    ...mapState('asset', ['assets']),
    ...mapState('filter', {
      currentMassId: (state) => state.currentMassId,
      interval: (state) => state.interval,
      rangeType: (state) => state.rangeType,
    }),
    currAsset() {
      const currMassId = this.$store.getters['filter/currentMassId'];
      const assets = this.$store.getters['asset/assets'];
      if (!assets) return null;

      return assets.find((asset) => asset.massId === currMassId);
    },
    firstGpsData() {
      if (!this.currAsset?.summary?.gps?.data) return null;
      const data = Object.values(this.currAsset?.summary?.gps?.data);
      return data ? data[0] : null;
    },

    lastGpsData() {
      if (!this.currAsset?.lastGpsData) return null;
      return this.currAsset?.lastGpsData;
    },
  },
  mounted() { },
  methods: {
    onApplyFilter() {
      this.$emit('applyFilter', {
        dateRange: this.dateRange,
        massId: this.selectedAsset,
      });
    },
    convertLatLngToDMS(latitude, longitude) {
      if (latitude === null || longitude === null) return '-, -';

      const latDegrees = Math.floor(Math.abs(latitude));
      const latMinutesDecimal = (Math.abs(latitude) - latDegrees) * 60;
      const latMinutes = Math.floor(latMinutesDecimal);
      const latSeconds = Math.round((latMinutesDecimal - latMinutes) * 60);

      const lngDegrees = Math.floor(Math.abs(longitude));
      const lngMinutesDecimal = (Math.abs(longitude) - lngDegrees) * 60;
      const lngMinutes = Math.floor(lngMinutesDecimal);
      const lngSeconds = Math.round((lngMinutesDecimal - lngMinutes) * 60);

      const latDirection = latitude >= 0 ? 'N' : 'S';
      const lngDirection = longitude >= 0 ? 'E' : 'W';

      return `${latDegrees}° ${latMinutes}' ${latSeconds}'' ${latDirection}, ${lngDegrees}° ${lngMinutes}' ${lngSeconds}'' ${lngDirection}`;
    },
    toHHMMSS,
    moment,
  },
};
</script>

<style scoped>
#voyage-sidebar {
  height: 100%;
  width: 35%;
  background: #FFFFFF;
  overflow-y: scroll;
}

#voyage-sidebar::-webkit-scrollbar {
  display: none;
}

#voyage-asset-summary {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 10px;
  padding: 10px 15px 10px 15px;
}

#voyage-asset-info {
  display: flex;
  flex-direction: column;
  border: 2px solid #E8ECF3;
  padding: inherit;
  border-radius: 20px;
}

#voyage-asset-info-img {
  width: inherit;
  height: 120px;
  object-fit: cover;
}

#voyage-asset-info-data {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;
  margin-top: 10px;
}

.voyage-asset-info-data-item-title {
  font-size: 12px;
  line-height: 22px;
  color: #768499;
}

.voyage-asset-info-data-item-value {
  font-size: 14px;
  line-height: 22px;
  color: #1E1E1E;
}

.onhire-badge {
  background-color: #55C95A;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}

.offhire-badge {
  background-color: #FF0000;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}

#voyage-asset-accumulations {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;
}

.voyage-asset-accumulation-card {
  display: flex;
  flex-direction: column;
  border: 2px solid #E8ECF3;
  border-radius: 20px;
  padding: 10px 15px;
}

.voyage-asset-accumulation-card-data {
  color: #264284;
}

.voyage-asset-accumulation-card-title {
  color: #425166;
}

#voyage-vessel-summary {
  padding: 10px 15px 10px 15px;
}

#voyage-vessel-summary hr {
  width: 100%;
  margin: 0;
  padding: 0;
}

#voyage-vessel-card {
  border: 2px solid #E8ECF3;
  border-radius: 20px
}

#voyage-vessel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

#voyage-vessel-location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

#voyage-vessel-first-location {
  text-align: left;
}

#voyage-vessel-duration {
  text-align: center;
}

#voyage-vessel-last-location {
  text-align: right;
}

.voyage-vessel-latlong {
  color: #E5398C;
  font-size: 16px;
  line-height: 24px;
}

.voyage-vessel-date {
  color: #768499;
  font-size: 12px;
  line-height: 22px;
}

.voyage-vessel-container {
  display: flex;
  overflow: hidden;
}

.voyage-vessel-column {
  flex: 1;
  padding: 15px 20px;
}

.voyage-left-column {
  border-right: 2px solid #E8ECF3;
}

#voyage-vessel-ae {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.voyage-vessel-data-title {
  color: #3F4C5F;
  font-size: 14px;
  line-height: 22px;
}

.voyage-vessel-data-value {
  color: #264284;
  font-size: 20px;
  line-height: 30px;
}

#view-datalog-button {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #E5398C;
  border-radius: 0px 0px 20px 20px;
}

#view-datalog-button h6 {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
}

#view-datalog-button:hover {
  cursor: pointer;
}
</style>

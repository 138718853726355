<template>
  <div id="map-section" style="height: calc(100vh - 70px); width: 100%" v-if="!isLoading">
    <Map :ships="assets" SelectedMap="default" />
    <ShipSection v-show="!isLoading" :ships="assets" @update-trail="()=>{}" @zoom-to-ship="zoomToShip" />
  </div>
  <div class="text-center text-danger my-2" v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong>Loading...</strong>
  </div>
</template>

<script>
import Map from '@/views/map';
import ShipSection from '@/views/home/components/ShipSection';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    Map,
    ShipSection,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('asset', {
      assets: (state) => state.assets.filter((asset) => asset.lastGpsData),
    }),
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      await this.$store.dispatch('asset/fetchAllLastSummary');
      this.isLoading = true;
    },
    zoomToShip(ship) {
      // eslint-disable-next-line no-undef
      this.center = L.latLng(ship.position[0], ship.position[1]);
      this.zoom = 15;
    },
  },
  async mounted() {
    await this.fetchData();
    this.isLoading = false;
  },
};
</script>

<template>
  <div class="voyage-wrapper">
    <voyage-sidebar @applyFilter="applyFilter" :isLoading="isLoading" @toggleMenu="toggleMenu" />
    <voyage-map v-if="ship" SelectedMap="default" :ships="[ship]"
      :Options="{ enableTrail: true, hideVoyageButton: true }" class="voyage-map" :key="isLoading" />
    <voyage-menu v-if="!isLoading && isMenuOpen" @toggleMenu="toggleMenu" :isMenuOpen="isMenuOpen"/>
  </div>
</template>

<script>
import moment from 'moment';
import Sidebar from '@/views/voyage_report/Sidebar';
import Map from '@/views/map/index';
import Menu from '@/views/voyage_report/Menu';

export default {
  name: 'VoyageReport',
  components: { VoyageSidebar: Sidebar, VoyageMap: Map, VoyageMenu: Menu },
  data() {
    return {
      isLoading: true,
      ship: null,
      isMenuOpen: false,
    };
  },
  async mounted() {
    const interval = this.$store.getters['filter/interval'];
    if (interval === 'minute') {
      this.$store.commit('filter/SET_INTERVAL', 'hour');
    }
    const date = this.$store.getters['filter/date'];

    let currentMassId = this.$store.getters['filter/currentMassId'];
    let assets = this.$store.getters['asset/assets'];

    if (assets.length === 0) {
      await this.$store.dispatch('asset/getAssetsData');
      await this.$store.dispatch('asset/getLastData');
      assets = this.$store.getters['asset/assets'];
    }

    if (currentMassId === null) {
      await this.$store.dispatch('filter/setCurrentMassId', assets[0].massId);
      currentMassId = this.$store.getters['filter/currentMassId'];
    }

    await this.$store.dispatch('asset/fetchSummary', {
      start: date.start,
      end: date.end,
      interval,
      massId: currentMassId,
    });

    this.ship = assets.find((asset) => asset.massId === currentMassId);
    this.isLoading = false;
  },
  computed: {
  },
  methods: {
    async applyFilter(options) {
      this.isLoading = true;
      await this.$store.dispatch('filter/setCurrentMassId', options.massId);
      await this.$store.dispatch('asset/fetchSummary', {
        start: moment(options.dateRange.start),
        end: moment(options.dateRange.end),
        interval: 'hour',
        massId: options.massId,
      });

      this.ship = this.$store.getters['asset/assets'].find((asset) => asset.massId === options.massId);
      this.isLoading = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
.voyage-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
}

.voyage-map {
  width: 65% !important;
}
</style>

<template>
  <ApexChart v-if="chartData && chartData.series" element="AuxiliaryEngineUsage" :chartOption="chartData"
    :series="chartData.series" />
</template>

<script>
import moment from 'moment';

export default {
  name: 'AuxiliaryEngineUsage',
  props: ['series'],
  computed: {
    chartData() {
      if (!this.series) return null;
      const isMobile = window.innerWidth <= 991;

      const MappedAe1FuelCons = this?.series?.map((data) => (data?.aeData?.AE1?.fuelConsumption || 0)?.toFixed(2));
      const MappedAe2FuelCons = this?.series?.map((data) => (data?.aeData?.AE2?.fuelConsumption || 0)?.toFixed(2));
      const MappedAe3FuelCons = this?.series?.map((data) => (data?.aeData?.AE3?.fuelConsumption || 0)?.toFixed(2));
      const MappedAe1RunningSeconds = this?.series?.map((data) => (data?.aeData?.AE1?.runningSeconds || 0)?.toFixed(2));
      const MappedAe2RunningSeconds = this?.series?.map((data) => (data?.aeData?.AE2?.runningSeconds || 0)?.toFixed(2));
      const MappedAe3RunningSeconds = this?.series?.map((data) => (data?.aeData?.AE3?.runningSeconds || 0)?.toFixed(2));

      let maxAEFuelCons = Math.max(...MappedAe1FuelCons, ...MappedAe2FuelCons, ...MappedAe3FuelCons);
      // eslint-disable-next-line no-mixed-operators
      maxAEFuelCons += (maxAEFuelCons / 100 * 10);

      let maxAERunningSeconds = Math.max(...MappedAe1RunningSeconds, ...MappedAe2RunningSeconds, ...MappedAe3RunningSeconds);
      // eslint-disable-next-line no-mixed-operators
      maxAERunningSeconds += (maxAERunningSeconds / 100 * 10);

      return {
        series: [
          {
            name: 'AE 1 Fuel Consumption (L)',
            data: MappedAe1FuelCons,
            color: '#FF0000', // Merah
          },
          {
            name: 'AE 2 Fuel Consumption (L)',
            data: MappedAe2FuelCons,
            color: '#00FF00', // Hijau
          },
          {
            name: 'AE 3 Fuel Consumption (L)',
            data: MappedAe3FuelCons,
            color: '#0000FF', // Biru
          },
          {
            name: 'AE 1 Running Seconds',
            data: MappedAe1RunningSeconds,
            color: '#FFFF00', // Kuning
          },
          {
            name: 'AE 2 Running Seconds',
            data: MappedAe2RunningSeconds,
            color: '#FF00FF', // Magenta
          },
          {
            name: 'AE 3 Running Seconds',
            data: MappedAe3RunningSeconds,
            color: '#00FFFF', // Cyan
          },
        ],
        chart: {
          height: 600,
          width: '100%',
          type: 'line',
          stacked: false,
        },
        markers: {
          size: 3,
        },
        stroke: {
          width: 3,
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter(val) {
              return moment(val).format('DD-MM-YYYY HH:mm');
            },
          },
          categories: this.series.map((data) => moment(data.timestamp * 1000).format('DD MMMM YYYY HH:mm')),
          tickAmount: isMobile ? 3 : undefined,
        },
        yaxis: [
          {
            max: maxAEFuelCons,
            title: {
              text: 'AE 1 Fuel Consumption',
            },
            show: !isMobile,
          },
          {
            max: maxAEFuelCons,
            title: {
              text: 'AE 2 Fuel Consumption',
            },
            show: !isMobile,
          },
          {
            max: maxAEFuelCons,
            title: {
              text: 'AE 3 Fuel Consumption',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: maxAERunningSeconds,
            title: {
              text: 'AE 1 Running Seconds',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: maxAERunningSeconds,
            title: {
              text: 'AE 2 Running Seconds',
            },
            show: !isMobile,
          },
          {
            opposite: true,
            max: maxAERunningSeconds,
            title: {
              text: 'AE 3 Running Seconds',
            },
            show: !isMobile,
          },
        ],
      };
    },
  },
  methods: {
  },
};
</script>

<style scoped></style>
